<template>
  <transition name="fade" appear class="">
    <div class="flex z-100 interest-modal">
      <div class="flex z-100 interest-modal__wrapper">
        <button
          class="interest-modal__close"
          title="Fechar"
          @click="$emit('close')"
        >
          <font-awesome-icon icon="times" />
        </button>
        <p class="my-4 text-center">
          Tem interesse ou dúvidas sobre este imóvel? Informe seus dados e
          entraremos em contato.
        </p>
        <form
          action=""
          class="w-full text-xs"
          v-on:submit.prevent="enviarContato"
        >
          <c-input
            label="Nome"
            class="w-full text-xs"
            placeholder="Digite seu nome"
            v-model="interest_form.nome"
            :invalid="$v.interest_form.nome.$invalid && isTouched"
          />
          <c-input
            label="Telefone"
            type="tel"
            class="w-full text-xs"
            mask="tel"
            v-model="interest_form.telefone"
            :invalid="$v.interest_form.telefone.$invalid && isTouched"
          />
          <c-input
            label="E-mail"
            type="email"
            class="w-full text-xs"
            placeholder="Digite seu e-mail"
            v-model="interest_form.email"
          />
          <c-input
            label="Observações"
            type="textarea"
            class="w-full text-xs"
            placeholder="Digite sua dúvida ou observação"
            v-model="interest_form.observacao"
          />
          <!--  @click="$emit('close')" -->
          <c-button class="w-full sidebar__submit primary">
            Enviar
          </c-button>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import CButton from "./UI/CButton.vue"
import CInput from "./UI/CInput.vue"
import axios from "axios"
import { required } from "vuelidate/lib/validators"
export default {
  components: {
    CInput,
    CButton
  },
  props: {
    codigo: { type: [String, Number], default: "0" },
    descricao: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      name: "",
      email: "",
      telefone: "",
      isTouched: false,
      interest_form: {
        nome: "",
        telefone: "",
        email: "",
        observacao: ""
      }
    }
  },
  mounted() {
    if (localStorage.name) {
      this.interest_form.nome = localStorage.name
    }
    if (localStorage.email) {
      this.interest_form.email = localStorage.email
    }
    if (localStorage.telefone) {
      this.interest_form.telefone = localStorage.telefone
    }
  },
  methods: {
    currentDateTime() {
      const current = new Date()
      const date = current.getFullYear() + "-" + (current.getMonth() + 1) + "-" + current.getDate()
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds()
      const dateTime = date + " " + time
      return dateTime
    },
    mandaprosleds(form) {
      let formData = new FormData()
      formData.append("fonte", window.location.href)
      formData.append("formulario", "Form. " + form + ": Imóvel " + this.codigo)
      formData.append("utm_medium", this.$route.query.utm_medium)
      formData.append("utm_source", this.$route.query.utm_source)
      formData.append("utm_campaign", this.$route.query.utm_campaign)
      formData.append("utm_id", this.$route.query.utm_id)
      formData.append("utm_term", this.$route.query.utm_term)
      formData.append("utm_content", this.$route.query.utm_content)
      formData.append("data", this.currentDateTime())
      formData.append("obs", "NC")
      axios.post("https://www.cafeimobiliaria.com.br/sistema/api/contatos/create", formData)
    },
    enviarContato() {
      let currentObj = this
      this.isTouched = true
      if (!this.$v.$invalid) {
        axios.post(
          "/api/enviarcontato.php", JSON.stringify({
            fonte: window.location.href,
            imovel: this.codigo + ": " + this.descricao,
            nome: this.interest_form.nome,
            email: this.interest_form.email,
            telefone: this.interest_form.telefone,
            observacao: this.interest_form.observacao,
            utm_medium: this.$route.query.utm_medium,
            utm_source: this.$route.query.utm_source,
            utm_campaign: this.$route.query.utm_campaign
          }))
          .then(function (response) {
            currentObj.output = response.data
          })
          .catch(function (error) {
            currentObj.output = error
          })
        this.isTouched = false
        // guarda em sessão
        localStorage.name = this.interest_form.nome
        localStorage.email = this.interest_form.email
        localStorage.telefone = this.interest_form.telefone
        // zera formulário
        this.interest_form.nome = ""
        this.interest_form.email = ""
        this.interest_form.telefone = ""
        this.interest_form.observacao = ""
        this.$snotify.info("Mensagem enviada com Sucesso!", {
          timeout: 1000,
          showProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          style: "dark"
        })
        if (this.$route.query.utm_medium) {
          this.mandaprosleds("Interesse")
        }
        this.$emit("close")
      }
    }
  },
  validations: {
    interest_form: {
      nome: { required },
      telefone: { required }
    }
  }
}
</script>

<style lang="postcss">
.interest-modal {
  @apply fixed top-0 left-0 w-full h-full z-30 flex justify-center items-center overflow-auto;
  background: rgba(0, 0, 0, 0.5);

  &__wrapper {
    @apply relative rounded bg-white flex p-8 bg-gray-200 flex flex-col items-center justify-center;
    width: 24em;
    max-width: 100vw;
  }

  &__close {
    @apply absolute top-0 right-0 p-4 px-5;

    &:focus {
      background: transparent;
      @apply text-primary-500;
    }
  }
}
</style>
