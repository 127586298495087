<template>
  <div class="property-search">
    <main-header />
    <c-section
      class="flex w-full p-0 property-search__wrapper"
      :container="false"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="blue"
        background-color="#000"
      ></loading>
      <!-- @mouseover="hover" -->
      <div class="hidden w-2/5 lg:block" v-show="mostrarmapa" style="position: relative;" v-if="!isMobile" >
        <!-- <google-map :lat="-29.6914" :lng="-53.8008">
        </google-map> -->
        <!-- <google-map :marcadores="marcadores" /> -->
        <gmap-map
          :center="center"
          :zoom="options.zoom"
          style="width:100%; height: 100%"
        >
          <gmap-cluster :zoomOnClick="true" :styles="clusterIcon">
            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
            </gmap-info-window>
            <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                @click="toggleInfoWindow(m)"
                :icon="'/icones/marcador_preto.png'"
            ></gmap-marker>
            <!-- :position="m.position"
            @click="center=m.position" -->
          </gmap-cluster>
        </gmap-map>
        <div class="aviso-mapa">
          <label for="" style="color: black;">Proximidades:</label>
          <br>
          <!-- <br> -->
          <a title="Universidade Franciscana - UFN" alt="Universidade Franciscana - UFN" href="/venda?proximidades=UFN" class="c-button btn-sobre-mapa" :class="$route.query.proximidades === 'UFN' ? 'primary' : ''">🏫 UFN</a>
          <a title="Calçadão de Santa Maria" alt="Calçadão de Santa Maria" href="/venda?proximidades=Calçadão" class="c-button btn-sobre-mapa" :class="$route.query.proximidades === 'Calçadão' ? 'primary' : ''">🛣️ Calçadão</a>
          <a title="Universidade Federal de Santa Maria UFSM" alt="Universidade Federal de Santa Maria UFSM" href="/venda?proximidades=UFSM" class="c-button btn-sobre-mapa" :class="$route.query.proximidades === 'UFSM' ? 'primary' : ''">🏫 UFSM</a>
        </div>
      </div>
      <!-- <buttom class="hidden mostra-esconde-mapa lg:block" @click="inputmapa" :alt="textomostramapa" :title="textomostramapa">
        <font-awesome-icon :icon="iconeabremapa" />
      </buttom> -->
        <!-- <font-awesome-icon icon="map-marker-alt" /> -->
      <div :class="classlista" v-touch:swipe.bottom="handleScroll" v-touch:swipe.up="handleScroll2">
        <!-- <div
          class="property-search__scrollable lg:w-3/5 sm:w-full"
          v-infinite-scroll="paginando"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="50"
          force-use-infinite-wrapper=".el-table__body-wrapper"
        > -->
        <transition name="fade-slide" mode="out-in">
          <aside
            class="property-search__drawer v-group"
            v-if="showFilters"
            :key="1"
            :contrato="'venda'"
          >
            <property-search-filters
              @close="showFilters = !showFilters"
              @pesquisar="pesquisandoimoveis"
              v-bind:imoveis_destaque="imoveis_destaque"
              v-on:filtros="addfiltros"
              :filtros="filtros"
            />
          </aside>

          <section
            class="py-0 property-search__drawer"
            v-if="!showFilters"
            :key="2"
          >
            <div
              class="sticky z-20 flex-col px-4 text-center bg-white property-search__title lg:flex-row lg:text-left"
            >
              <div class="mb-2 md:w-1/2 xl:mr-auto md:mb-0" v-show="oquemostrar.tituloresultados">
                <h3 class="text-sm font-medium md:text-lg md:font-normal">
                  Imóveis em
                  <span class="text-primary-500" v-if="cidade">{{ this.cidade }} e região</span>
                  <span class="text-primary-500" v-else>Santa Maria e arredores</span>
                </h3>
                <small>{{ this.contador_imoveis.length }} encontrados</small>
              </div>
              <div class="hidden mx-2 my-2 mb-2 pd md:w-1/5 xl:mr-auto md:mb-0 lg:block estilo-toggle-2024">
                <h3 class="text-sm font-medium md:text-lg md:font-normal">Ver mapa</h3>
                <toggle-button
                  @change="inputmapa"
                  :value="mostrarmapa"
                  color="#1F362E"
                />
              </div>
              <div class="hidden mx-2 my-2 mb-2 pd md:w-1/5 xl:mr-auto md:mb-0 lg:block estilo-toggle-2024">
                <h3 class="text-sm font-medium md:text-lg md:font-normal">Aceita Permuta</h3>
                <toggle-button
                  @change="imoveispermutaveis"
                  :value="ativarpermutas"
                  color="#1F362E"
                />
              </div>
              <div class="flex justify-center w-full md:w-1/2">
                <c-select
                  placeholder="Ordenar por"
                  :options="formOptions.ordenacao"
                  class="w-1/2 mb-0 mr-2 text-xs"
                  v-model="ordenador"
                  @input="ordenarresultados"
                ></c-select>
                <c-button
                  :class="filtroativo"
                  @click="showFilters = !showFilters"
                  icon="sliders-h"
                  :title="textopesquisa"
                >
                  Filtros <strong style="font-size: 15px" v-if="contafiltros > 0">  ({{ contafiltros }})</strong>
                </c-button>
              </div>
            </div>
            <!-- <div @click="showFilters = !showFilters" class="justify-center hidden pesquisa-feita text-primary-500 md:block" v-if="filtrosativos">
              <label>Filtros Ativos</label>
            </div> -->
            <div class="flex flex-wrap px-2">
              <div :class="gridClasses" v-for="item in pesquisacidades" :key="item.codigo">
                <property-card
                  class="w-full"
                  v-bind:codigo="item.codigo"
                  v-bind:pagina="'venda'"
                  v-bind:contrato="item.contrato"
                  v-bind:tipo="item.tipo"
                  v-bind:subtipo="item.subtipo"
                  v-bind:imagem="item.imagem"
                  v-bind:bairro="item.endereco_bairro"
                  v-bind:cidade="item.endereco_cidade"
                  v-bind:estado="item.endereco_estado"
                  v-bind:endereco_cidade="item.endereco_cidade"
                  v-bind:endereco="item.endereco_logradouro"
                  v-bind:empreendimento="item.condominio_nome"
                  v-bind:dormitorios="item.dormitorios"
                  v-bind:suites="item.suites"
                  v-bind:vagas="item.garagens"
                  v-bind:area="item.area_privativa"
                  v-bind:area_privativa="item.area_privativa"
                  v-bind:area_util="item.area_util"
                  v-bind:area_total="item.area_total"
                  v-bind:valor_venda="item.valor_venda"
                  v-bind:valor_locacao="item.valor_locacao"
                  v-bind:exclusivo="item.exclusividade"
                  v-bind:posicao_solar="item.posicao_solar"
                  v-bind:comodidades="item.imovel_comodidades"
                  v-bind:terreno_largura="item.terreno_largura"
                  v-bind:terreno_comprimento="item.terreno_comprimento"
                  v-bind:mobiliado="item.mobiliado"
                  v-bind:status="item.situacao_condominio"
                  v-bind:tags="item.tags"
                  v-bind:propertyPhotos="item.galeria_thumb"
                  v-bind:permuta="item.permuta"
                />
              </div>
              <infinite-loading @infinite="paginando" spinner="spiral">
                <div slot="spinner">Loading...</div>
                <div slot="no-more">Fim da listagem</div>
                <div slot="no-results">Sem resultados</div>
              </infinite-loading>
              <!-- v-bind:banheiros="item.banheiros" -->
            </div>
          </section>
        </transition>
      </div>
    </c-section>
  </div>
</template>
<script>
import PropertySearchFilters from "../components/PropertySearchFilters.vue"
// import GoogleMap from "../components/GoogleMap.vue"
// import GoogleMapMarker from "../components/GoogleMapMarker.vue"
// import GoogleMap from "../components/GMap"
import PropertyCard from "../components/PropertyCard.vue"
import CSection from "../components/UI/CSection.vue"
import CButton from "../components/UI/CButton.vue"
import CSelect from "../components/UI/CSelect.vue"
import MainHeader from "../components/MainHeader.vue"
import EstadoCidadeMixin from "../mixins/EstadoCidadeMixin"
import FormOptionsMixin from "../mixins/FormOptionsMixin"
import InfiniteLoading from "vue-infinite-loading"
// import CRangeSlider from "../components/UI/CRangeSlider.vue"
import axios from "axios"
// import json from "json"
import { icon } from "@fortawesome/fontawesome-svg-core"
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Pesquisa de Imóveis à venda",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Venda, Comprar Imóveis"
      }
    ]
  },
  components: {
    MainHeader,
    CSelect,
    CButton,
    CSection,
    PropertyCard,
    // CRangeSlider,
    // GoogleMap,
    InfiniteLoading,
    // GoogleMapMarker,
    PropertySearchFilters,
    Loading
  },
  mixins: [EstadoCidadeMixin, FormOptionsMixin],
  data() {
    return {
      filtroativo: "w-1/2 text-xs rounded-lg primary",
      mostrarmapa: false,
      ativarpermutas: false,
      classlista: "property-search__scrollable lg:w-5/5 sm:w-full",
      iconeabremapa: "caret-left",
      textomostramapa: "Ocultar o Mapa",
      marcadores: [],
      nomeCliente: "Meu nome X",
      showFilters: false,
      windowWidth: 0,
      gridClasses: "px-2 pb-4 xl:w-1/4 md:w-1/2 xs:w-1/1", // Muda quantos por fila
      imoveis_destaque: [],
      cidadesFromAPI: [],
      bairrosFromAPI: [],
      pesquisacidades: [],
      contador_imoveis: [],
      fitrandopermutas: [],
      cidade: {},
      bairro: {},
      // dormitorios: {},
      dormitorios: [],
      garagens: {},
      banheiros: {},
      valor: {},
      area_util: {},
      tags: "",
      proximidades: "",
      campanha: "",
      // orientacao_solar: {},
      comodidades: {},
      condominio_nome: {},
      ordenador: [],
      paginas: [],
      page: 1,
      initpage: 1,
      fimdpage: 15,
      busy: false,
      caracteres: [],
      ordenacao: [
        {
          id: "mais_recentes",
          label: "Mais recentes"
        },
        {
          id: "maior_preco",
          label: "Maior preço"
        },
        {
          id: "menor_preco",
          label: "Menor preço"
        }
      ],
      center: { lat: -29.6914, lng: -53.8008 },
      places: [],
      markers: [],
      currentPlace: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35
        },
        width: 380
      },
      options: {
        zoom: 14
      },
      icones: {
        bed: icon({ prefix: "fas", iconName: "bed" }),
        car: icon({ prefix: "fas", iconName: "car" }),
        expandalt: icon({ prefix: "fas", iconName: "expand-alt" }),
        dooropen: icon({ prefix: "fas", iconName: "door-open" })
      },
      pesquisafeita: "",
      filtrosativos: false,
      filtros: [],
      contafiltros: 0,
      textopesquisa: "Pesquise aqui:",
      /**
       * position: absolute; top: 0px; left: 0px; clip: rect(0px, 56px, 56px, 0px);
       */
      isLoading: false,
      clusterIcon: [
        {
          url: "/icones/clusters/cluster-marcador-1.png",
          width: "53",
          height: "53"
        },
        {
          url: "/icones/clusters/cluster-marcador-2.png",
          width: "53",
          height: "53"
        },
        {
          url: "/icones/clusters/cluster-marcador-3.png",
          width: "53",
          height: "53"
        }
      ],
      fullPage: true,
      proximidadesroute: "",
      proxCoord: "",
      proxRaio: "",
      proximidadesSM: {
        camobi: {
          lat: -29.702158,
          lng: -53.721131
        },
        ufsm: {
          lat: -29.720701450498595,
          lng: -53.71441177724734
        },
        ufn: {
          lat: -29.685457561260765,
          lng: -53.814266573881255
        },
        calcadao: {
          lat: -29.686466453568745,
          lng: -53.807623448370215
        }
      },
      oquemostrar: {
        tituloresultados: true
      }
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth < 1024
    },
    contpaginas() {
      var contagem = this.contador_imoveis.length / 30
      // if (contagem <= 0) {
      //   contagem = 1
      // }
      // console.log("páginas (vl) " + contagem)
      // console.log("páginas (fx) " + parseInt(contagem))
      // console.log("resto " + (contagem % 5))
      if (parseInt(contagem) < contagem) {
        return parseInt(contagem) + 1
      } else {
        return contagem
      }
    },
    urlcomplementos() {
      var retorno = ""
      if (window.location.href.includes("utm_")) {
        // (this.$route.query) ? retorno += "&" : retorno += "?"
        // retorno += "?registros=cafe"
        if (this.$route.query.utm_source) {
          retorno += "&utm_source=" + this.$route.query.utm_source
        }
        if (this.$route.query.utm_medium) {
          retorno += "&utm_medium=" + this.$route.query.utm_medium
        }
        if (this.$route.query.utm_campaign) {
          retorno += "&utm_campaign=" + this.$route.query.utm_campaign
        }
        if (this.$route.query.utm_id) {
          retorno += "&utm_id=" + this.$route.query.utm_id
        }
        if (this.$route.query.utm_term) {
          retorno += "&utm_term=" + this.$route.query.utm_term
        }
        if (this.$route.query.utm_content) {
          retorno += "&utm_content=" + this.$route.query.utm_content
        }
        return retorno
      } else {
        return ""
      }
    }
  },
  created() {
    // console.log(this.$route.query)
    // this.isLoading = true
    if (Object.keys(this.$route.query).length === 0) {
      this.filtrosativos = false
    } else {
      this.filtrosativos = true
      this.filtroativo = "w-1/2 text-xs rounded-lg primary botao-ativo"
    }
    this.tipo_de_imovel = this.$route.query.tipo_de_imovel
    this.condominio_nome = this.$route.query.condominio_nome
    if (this.$route.query.condominio) {
      this.condominio_nome = this.$route.query.condominio
    }
    this.cidade = this.$route.query.cidade
    this.bairro = this.$route.query.bairros
    this.dormitorios = this.$route.query.dormitorios
    this.garagens = this.$route.query.garagens
    this.banheiros = this.$route.query.banheiros
    this.valor = this.$route.query.valor
    this.area_util = this.$route.query.area_util
    this.comodidades = this.$route.query.comodidades
    if (this.$route.query.tags) {
      this.tags = this.$route.query.tags
    }
    if (this.$route.query.campanha) {
      this.campanha = this.$route.query.campanha
    }
    var itens = 0
    var arrrec = this.$route.query
    var textopesquisa2 = "Pesquisa: \n"
    Object.keys(arrrec).forEach(function(item) {
      // var arrtipos = ["0", 0, "0+", "0,1000000,"]
      if (
        arrrec[item] !== "" &&
        arrrec[item] !== "0" &&
        arrrec[item] !== "0 " &&
        arrrec[item] !== "0+" &&
        arrrec[item] !== "0,1000000," &&
        arrrec[item] !== 0 &&
        arrrec[item] !== null &&
        arrrec[item] !== "null" &&
        arrrec[item] !== undefined &&
        arrrec[item] !== "undefined" &&
        item !== "utm_medium" &&
        item !== "utm_source" &&
        item !== "utm_campaign" &&
        item !== "utm_content"
      ) {
        itens += 1
        var campo = item
        switch (item) {
          case "tipo_de_imovel": campo = "tipo"
            break
          case "dormitorios": campo = "cômodos"
            break
        }
        textopesquisa2 += campo + ": " + arrrec[item] + " \n"
      }
      // console.log(item + " = " + arrrec[item])
    })
    this.textopesquisa = textopesquisa2
    this.contafiltros = itens
    var tipos = "Todos"
    if (this.tipo_de_imovel && this.tipo_de_imovel !== undefined && this.tipo_de_imovel !== "null") {
      tipos = this.tipo_de_imovel.split(",")
    }
    if (this.cidade === "null" || this.cidade === undefined || this.cidade === "undefined") {
      this.cidade = ""
    }
    var bairros = "Todos"
    if (this.bairro && this.bairro !== undefined && this.bairro !== "null" && this.bairro !== "undefined") {
      bairros = this.bairro.split(",")
    }
    var condominio = ""
    if (this.condominio_nome && this.condominio_nome !== undefined && this.condominio_nome !== "null" && this.condominio_nome !== "undefined") {
      condominio = this.condominio_nome
    }
    // if (this.dormitorios === "null" || this.dormitorios === undefined || this.dormitorios === "undefined") {
    //   this.dormitorios = "0"
    // } else {
    //   this.dormitorios.replace(" ", "")
    // }
    /**
      * Novas funções para pesquisa multipla de dormitórios
    */
    var dormitoriosy = "Todos"
    if (this.dormitorios && this.dormitorios !== undefined && this.dormitorios !== "null" && this.dormitorios !== "undefined") {
      this.dormitorios = this.dormitorios.replace("+", "")
      this.dormitorios = this.dormitorios.replace(" ", "")
      dormitoriosy = this.dormitorios.split(",")
    }
    if (this.garagens === "null" || this.garagens === undefined || this.garagens === "undefined") {
      this.garagens = "0"
    } else {
      this.garagens.replace(" ", "")
    }
    if (this.banheiros === "null" || this.banheiros === undefined || this.banheiros === "undefined") {
      this.banheiros = "0"
    } else {
      this.banheiros.replace(" ", "")
    }
    var valores = [0, 1000000]
    if (this.valor && this.valor !== undefined && this.valor !== "null" && this.valor !== "undefined") {
      valores = this.valor.split(",")
    }
    if (this.area_util === "null" || this.area_util === undefined || this.area_util === "undefined") {
      this.area_util = "0"
    }
    var maiscomodidades = "Todos"
    if (this.comodidades && this.comodidades !== undefined && this.comodidades !== "null" && this.comodidades !== "undefined") {
      var thiscomodidades = this.comodidades.replace(/.$/, "")
      maiscomodidades = thiscomodidades.split(",")
    }
    // var proximidadesroute = ""
    // var proxCoord = []
    // var proxRaio = 1
    if (this.$route.query.proximidades && this.$route.query.proximidades !== "") {
      this.proximidadesroute = this.$route.query.proximidades
      switch (this.proximidadesroute) {
        case "UFN":
          this.proxCoord = this.proximidadesSM.ufn
          this.proxRaio = 1
          this.center = this.proxCoord
          break
        case "UFSM":
          this.proxCoord = this.proximidadesSM.ufsm
          this.proxRaio = 2
          this.center = this.proxCoord
          break
        case "Calçadão":
          this.proxCoord = this.proximidadesSM.calcadao
          this.center = this.proxCoord
          this.proxRaio = 1
          break
      }
    }
    axios.get("jsons/cafeimoveis.json")
      .then((response) => {
        this.imoveis_destaque = response.data
        this.contador_imoveis = this.imoveis_destaque.filter(
          d =>
            (d.contrato === "Compra" || d.contrato === "Compra,Locação") &&
            ((tipos === "Todos" || tipos.includes(d.subtipo) || tipos.includes(d.tipo)) ||
            (tipos.includes("Casa") && (d.subtipo === "Casa" || d.subtipo === "Sobrado")) ||
            (tipos.includes("Apartamento") && (
              d.subtipo === "Cobertura" ||
              d.subtipo === "Kitnet" ||
              d.subtipo === "Duplex" ||
              d.subtipo === "Triplex" ||
              d.subtipo === "Loft"
            )) ||
            (tipos.includes("Cobertura") && (
              d.subtipo === "Duplex" ||
              d.subtipo === "Triplex"
            )) ||
            (tipos.includes("Duplex") && (
              d.subtipo === "Duplex" ||
              d.observacoes.indexOf("Duplex") !== -1 ||
              d.observacoes.indexOf("duplex") !== -1
            )) ||
            (tipos.includes("Triplex") && (
              d.subtipo === "Triplex" ||
              d.observacoes.indexOf("Triplex") !== -1 ||
              d.observacoes.indexOf("triplex") !== -1
            )) ||
            (tipos.includes("Chácara/Sítio") && (
              d.subtipo === "Chácara" ||
              d.subtipo === "Sítio"
            )) ||
            (tipos.includes("Campo/Fazenda") && (
              d.subtipo === "Campo" ||
              d.subtipo === "Fazenda"
            )) ||
            (tipos.includes("Kitnet/Loft/Studio") && (
              d.subtipo === "Kitnet" ||
              d.subtipo === "Loft" ||
              d.subtipo === "Studio"
            ))) &&
            ((d.endereco_cidade === this.cidade) ||
            (this.cidade === "" && (
              d.endereco_cidade === "Santa Maria" ||
              d.endereco_cidade === "Itaara" ||
              d.endereco_cidade === "Silveira Martins" ||
              d.endereco_cidade === "São Francisco de Assis" ||
              d.endereco_cidade === "São Martinho da Serra"
            )) ||
            (this.cidade === "Santa Maria" && (
              d.endereco_cidade === "Santa Maria" ||
              d.endereco_cidade === "Itaara" ||
              d.endereco_cidade === "Silveira Martins" ||
              d.endereco_cidade === "São Francisco de Assis" ||
              d.endereco_cidade === "São Martinho da Serra"
            )) ||
            (this.cidade === "Balneário Camboriú" && (
              d.endereco_cidade === "Balneário Camboriú" ||
              d.endereco_cidade === "Balneário Piçarras" ||
              d.endereco_cidade === "Itapema" ||
              d.endereco_cidade === "Itajaí"
            ))
            ) &&
            (bairros === "Todos" || bairros.includes(d.endereco_bairro)) &&
            (condominio === "" || d.condominio_nome === condominio) &&
            // d.dormitorios >= parseInt(this.dormitorios) &&
            (dormitoriosy === "Todos" || dormitoriosy.includes(String(d.dormitorios)) || (
              dormitoriosy.includes("5") && d.dormitorios >= 5
            )) &&
            d.garagens >= parseInt(this.garagens) &&
            d.banheiros >= parseInt(this.banheiros) &&
            (
              d.area_privativa >= parseInt(this.area_util) ||
              // d.area_util >= parseInt(this.area_util) ||
              // d.area_total >= parseInt(this.area_util) ||
              (d.subtipo === "Terreno" && (d.terreno_comprimento * d.terreno_largura >= parseInt(this.area_util))) ||
              (
                d.medida === "ha" && (
                  (d.area_privativa * 10000) >= parseInt(this.area_util) ||
                  // (d.area_util * 10000) >= parseInt(this.area_util) ||
                  // (d.area_total * 10000) >= parseInt(this.area_util) ||
                  ((d.terreno_comprimento * d.terreno_largura) * 10000) >= parseInt(this.area_util)
                )
              )
            ) &&
            (
              (valores[1] < 1000000 && (d.valor_venda >= valores[0] && d.valor_venda <= valores[1])) ||
              (valores[1] > 999999 && d.valor_venda >= valores[0])
            ) &&
            (
              (maiscomodidades === "Todos" || (d.imovel_comodidades !== null && this.objetivacomodidades((d.imovel_comodidades + "").split(","), maiscomodidades))) ||
              ((d.condominio_comodidades !== "" && d.condominio_comodidades !== null) && this.objetivacomodidades((d.condominio_comodidades + "").split(","), maiscomodidades))
            ) &&
            (this.tags === "" || (this.tags !== "" && d.tags.indexOf(this.tags) !== -1)) &&
            (this.proximidadesroute === "" || (this.proximidadesroute !== "" && this.nasproximidadesProcessa(this.proxRaio, d.latitude, d.longitude, this.proxCoord.lat, this.proxCoord.lng)))
            // &&
            // (this.campanha === "" || (this.campanha !== "" && d.tags.indexOf(this.campanha) !== -1))
        )

        this.pesquisacidades = this.contador_imoveis.sort(function(a, b) {
          return b.codigo - a.codigo
        })
        // ordenando a campanha na frente
        var seqimoveis1 = []
        var seqimoveis2 = []
        // var seq_unidos = []
        if (this.campanha === "carnaval23") {
          seqimoveis1 = this.pesquisacidades.filter(d =>
            d.tags.indexOf(this.campanha) !== -1
          )
          // } else {
          seqimoveis2 = this.pesquisacidades.filter(d =>
            d.tags.indexOf(this.campanha) === -1
          )
          // console.log(seqimoveis1)
          // console.log(seqimoveis2)
          // }
          this.pesquisacidades = seqimoveis1.concat(seqimoveis2)
          // this.pesquisacidades = this.pesquisacidades.slice(0, this.fimdpage)
        }
        // Ordena os destaques na frente
        seqimoveis1 = this.pesquisacidades.filter(d =>
          d.tags.indexOf("destaque") !== -1
        )
        seqimoveis2 = this.pesquisacidades.filter(d =>
          d.tags.indexOf("destaque") === -1
        )
        seqimoveis1 = this.shuffle(seqimoveis1)
        this.pesquisacidades = seqimoveis1.concat(seqimoveis2)
        this.pesquisacidades = this.pesquisacidades.slice(0, this.fimdpage)
        // FIM do ordenando a campanha na frente

        var localidades = []
        var allimoveisl = []
        var imoveiscomerciais = ["Sala Comercial", "Casa Comercial", "Loja"]
        if (!this.isMobile) {
          this.contador_imoveis.forEach(pin => {
            var valor = "Consulte-nos"
            if (pin.valor_venda > 0) {
              valor = this.formataValor(pin.valor_venda)
            }
            localidades.push(String(pin.latitude + ";" + pin.longitude))
            var supercard = ""
            supercard += "<div class='card-do-mapa'>"
            supercard += "<a href='imovel/" + pin.codigo + "' target='_blank'>"
            supercard += "<div class='imagem-do-mapa'>"
            supercard += "<label class='mapa-card-codigo'><strong>Cod. " + pin.codigo + "</strong></label>"
            if (pin.thumb) {
              supercard += "<img src='" + pin.thumb + "' style='width: 130px;' />"
            } else {
              supercard += "<img src='/img/logo_a_empresa.png' style='width: 100px;padding-top: 30%;' />"
            }
            supercard += "</div>"
            supercard += "<div class='clearfix'></div>"
            supercard += "<h4 class='texto-do-mapa'>"
            supercard += "<label class='mapa-card-tipo'><strong>"
            supercard += this.abrevia(pin.subtipo)
            supercard += "</strong></label>"
            if (pin.dormitorios > 0) {
              supercard += "<label class='mapa-card-dormitorios'>"
              supercard += pin.dormitorios
              if (imoveiscomerciais.indexOf(pin.subtipo) !== -1) {
                supercard += " " + this.icones.dooropen.html
              } else {
                supercard += " " + this.icones.bed.html
              }
              supercard += "</label>"
            }
            if (pin.garagens > 0) {
              supercard += "<label class='mapa-card-garagens'>"
              supercard += pin.garagens + " " + this.icones.car.html
              supercard += "</label>"
            }
            if (pin.area_privativa > 0) {
              supercard += "<label class='mapa-card-garagens'>"
              supercard += Number(pin.area_privativa).toFixed(0) + " m²"
              supercard += "</label>"
            }
            if (pin.subtipo === "Terreno") {
              supercard += "<label class='mapa-card-garagens'>"
              if (pin.terreno_largura > 0) {
                supercard += this.icones.expandalt.html + " " + pin.terreno_largura + " "
                if (pin.terreno_comprimento > 0) {
                  supercard += " x " + pin.terreno_comprimento
                }
                supercard += " m"
              }
              supercard += "</label>"
            }
            supercard += "</h4>"
            supercard += "<br />"
            supercard += "<label class='mapa-card-valor'><strong>"
            supercard += valor
            supercard += "</strong></label>"
            // supercard += "</div>"
            supercard += "</a>"
            supercard += "</div>"
            allimoveisl.push({
              coord: String(pin.latitude + ";" + pin.longitude),
              infos: supercard
            })
          })
          localidades = ([...new Set(localidades)])
          localidades.forEach(local => {
            var position = local.split(";")
            var imview = ""
            allimoveisl.forEach(element => {
              if (element.coord === local) {
                imview += element.infos + "<br>"
              }
            })
            this.addMarker(Number(position[0]), Number(position[1]), imview)
          })
        }
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
      })
    // if (this.$route.query.utm_medium) {
    //   // console.log("essa parada aqui é o utm_campaign: " + this.$route.query.utm_campaign)
    //   axios.post(
    //     "http://localhost/api/registrador.php", JSON.stringify({
    //       fonte: window.location.href,
    //       utm_medium: this.$route.query.utm_medium,
    //       utm_source: this.$route.query.utm_source,
    //       utm_campaign: this.$route.query.utm_campaign
    //     }))
    // }
  },
  methods: {
    // Evento do Scroll:
    handleScroll2() {
      this.oquemostrar.tituloresultados = false
      // console.log("Scrolling down")
    },
    handleScroll() {
      // console.log("Scrolling")
      // var currentScrollPosition = e.srcElement.scrollTop
      // if (currentScrollPosition > this.scrollPosition) {
      //   console.log("Scrolling down")
      //   this.oquemostrar.tituloresultados = false
      // } else {
      // console.log("Scrolling up")
      this.oquemostrar.tituloresultados = true
      // }
      // this.scrollPosition = currentScrollPosition
      // var lastX, lastY
    },
    // Buscar nas Proximidades
    nasproximidadesProcessa(distanciapedida, origemLat, origemLng, destinoLat, destinoLng) {
      var p1 = Math.cos((90 - origemLat) * Math.PI / 180)
      var p2 = Math.cos((90 - destinoLat) * Math.PI / 180)
      var p3 = Math.sin((90 - origemLat) * Math.PI / 180)
      var p4 = Math.sin((90 - destinoLat) * Math.PI / 180)
      var p5 = Math.cos((origemLng - destinoLng) * (Math.PI / 180))
      var KM = ((Math.acos((p1 * p2) + (p3 * p4 * p5)) * 6371) * 1.15)
      // console.log("origemLat: " + origemLat)
      // console.log("origemLng: " + origemLng)
      // console.log("destinoLat: " + destinoLat)
      // console.log("destinoLng: " + destinoLng)
      // controle 2
      // console.log("Distância Pedida: " + distanciapedida)
      // console.log("Distância Encontrada: " + KM)
      if (KM <= distanciapedida) {
        return true
      } else {
        return false
      }
    },
    updateWidth({ target }) {
      this.windowWidth = target.innerWidth
    },
    inputmapa() {
      this.mostrarmapa = !this.mostrarmapa
      if (this.mostrarmapa) {
        this.classlista = "property-search__scrollable lg:w-3/5 sm:w-full"
        this.gridClasses = "px-2 pb-4 xl:w-1/2 md:w-1/2 xs:w-1/1"
        this.iconeabremapa = "caret-left"
        this.textomostramapa = "Ocultar o Mapa"
      } else {
        this.classlista = "property-search__scrollable lg:w-5/5 sm:w-full"
        this.gridClasses = "px-2 pb-4 xl:w-1/4 md:w-1/2 xs:w-1/1"
        this.iconeabremapa = "caret-right"
        this.textomostramapa = "Mostra o Mapa"
      }
    },
    verseexiste(arr1, arr2) {
      arr1.sort()
      arr2.sort()
      var arr3 = []
      for (var i = 0; i <= arr1.length; i++) {
        if (arr2.indexOf(arr1[i]) > -1) {
          arr3.push(arr1[i])
        }
      }
      return arr3
    },
    compare(ar1, ar2) {
      ar1.sort()
      ar2.sort()
      if (ar1.length !== ar2.length) {
        return false
      }
      for (var i = 0; i < ar1.length; i++) {
        if (ar1[i] !== ar2[i]) {
          return false
        }
      }
      return true
    },
    objetivacomodidades(arr1, arr2) {
      // arr1 = Object.values(arr1)
      // arr1.join(",")
      var novoarr = []
      for (let j = 0; j < arr1.length; j++) {
        const element = arr1[j]
        // console.log(element)
        const el = element.trim()
        novoarr.push(el)
      }
      novoarr = novoarr.join(",")
      var arr3 = this.verseexiste(novoarr.split(","), arr2)
      return this.compare(arr2, arr3)
    },
    ordenarresultados() {
      // console.log(this.ordenador)
      if (this.ordenador === "maior_preco") {
        this.contador_imoveis.sort(function(a, b) {
          return b.valor_venda - a.valor_venda
        })
      }
      if (this.ordenador === "menor_preco") {
        this.contador_imoveis.sort(function(a, b) {
          return a.valor_venda - b.valor_venda
        })
      }
      if (this.ordenador === "mais_recentes") {
        this.contador_imoveis.sort(function(a, b) {
          return b.codigo - a.codigo
        })
      }
      this.pesquisacidades = this.contador_imoveis.slice(0, this.fimdpage)
      // return null
      // if (this.ordenador === "maior_preco") {
      //   this.pesquisacidades.sort(function(a, b) {
      //     return b.valor_venda - a.valor_venda
      //   })
      // }
    },
    paginando($state) {
      if (!this.showFilters) {
        // if (!this.isMobile) {
        //   // console.log("scroooooooool")
        this.busy = false
        this.page = this.page + 1
        this.paginando_pagina(this.page)
        $state.loaded()
        // } else {
        //   this.page = this.page + 1
        //   this.paginando_pagina(this.page)
        // }
      }
      if (this.pesquisacidades.length === this.contador_imoveis.length && this.contador_imoveis.length > 0) {
        $state.complete()
      }
    },
    paginando_pagina(pagina) {
      // this.$snotify.clear()
      // this.$snotify.info("Carregando imóveis!", {
      //   timeout: 1000,
      //   showProgressBar: true,
      //   closeOnClick: false,
      //   pauseOnHover: true
      // })
      this.page = pagina
      this.initpage = (this.page - 1) * 15
      this.fimdpage = this.initpage + 15
      // console.log("Página: " + this.page)
      // console.log("Primeiro ítem: " + this.initpage)
      // console.log("Último ítem: " + this.fimdpage)
      this.pesquisacidades = this.contador_imoveis
      var seqimoveis1 = []
      var seqimoveis2 = []
      // var seq_unidos = []
      if (this.campanha === "carnaval23") {
        seqimoveis1 = this.pesquisacidades.filter(d =>
          d.tags.indexOf(this.campanha) !== -1
        )
        // } else {
        seqimoveis2 = this.pesquisacidades.filter(d =>
          d.tags.indexOf(this.campanha) === -1
        )
        // console.log(seqimoveis1)
        // console.log(seqimoveis2)
        // }
        this.pesquisacidades = seqimoveis1.concat(seqimoveis2)
        // this.pesquisacidades = this.pesquisacidades.slice(0, this.fimdpage)
        this.pesquisacidades = this.pesquisacidades.slice(0, this.fimdpage)
      } else {
        // Ordena os destaques na frente
        seqimoveis1 = this.pesquisacidades.filter(d =>
          d.tags.indexOf("destaque") !== -1
        )
        seqimoveis2 = this.pesquisacidades.filter(d =>
          d.tags.indexOf("destaque") === -1
        )
        this.pesquisacidades = seqimoveis1.concat(seqimoveis2)
        this.pesquisacidades = this.pesquisacidades.slice(0, this.fimdpage)
      }
      // if (this.pesquisacidades.length === this.contador_imoveis.length && this.contador_imoveis.length > 0) {
      //   this.carregandoitens = false
      // }
    },
    // Métodos para o Mapa
    setPlace(place) {
      this.currentPlace = place
    },
    addMarker(lat, lng, content) {
      const marker = {
        lat: lat,
        lng: lng
      }
      this.markers.push({ position: marker, infoText: content })
      this.places.push(this.currentPlace)
      // this.center = marker
      this.currentPlace = null
    },
    geolocate: function(lat, lng) {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: lat,
          lng: lng
        }
      })
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position
      this.infoOptions.content = marker.infoText
      this.infoWinOpen = true
      this.currentMidx = idx
    },
    formataValor(valor) {
      const formatter = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
      return `${formatter.format(valor)}`
    },
    abrevia(str) {
      var retorno = ""
      switch (str) {
        case "Apartamento": retorno = "Apto"
          break
        default: retorno = str
          break
      }
      return retorno.substr(0, 4) + "."
    },
    addfiltros (value) {
      this.filtros = value
    },
    imoveispermutaveis() {
      this.isLoading = true
      this.ativarpermutas = !this.ativarpermutas
      // console.log("traz os que aceitam permuta")
      // console.log(this.ativarpermutas)
      if (this.ativarpermutas) {
        this.contador_imoveis = this.contador_imoveis.filter(
          d => (d.permuta === true)
        )
      } else {
        location.reload()
      }
      this.pesquisacidades = this.contador_imoveis.slice(0, this.fimdpage)
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    pesquisandoimoveis() {
      this.isLoading = true
      this.markers = []
      this.contafiltros = 0
      var itens = 0
      var arrrec = this.filtros
      var textopesquisa2 = "Pesquisa: \n"
      Object.keys(arrrec).forEach(function(item) {
        // var arrtipos = ["0", 0, "0+", "0,1000000,"]
        if (
          arrrec[item] !== "" &&
          arrrec[item] !== "0" &&
          arrrec[item] !== "0+" &&
          arrrec[item] !== "0,1000000," &&
          arrrec[item] !== 0
        ) {
          itens += 1
          var campo = item
          switch (item) {
            case "tipo_de_imovel": campo = "tipo"
              break
            case "dormitorios": campo = "cômodos"
              break
          }
          textopesquisa2 += campo + ": " + arrrec[item] + " \n"
        }
        // console.log(item + " = " + arrrec[item])
      })
      this.textopesquisa = textopesquisa2

      this.contafiltros = itens
      if (this.contafiltros > 0) {
        this.filtroativo = "w-1/2 text-xs rounded-lg primary botao-ativo"
      } else {
        this.filtroativo = "w-1/2 text-xs rounded-lg primary"
      }

      this.tipo_de_imovel = this.filtros.tipo_de_imovel
      this.cidade = this.filtros.cidade
      this.bairro = this.filtros.bairros
      this.dormitorios = this.filtros.dormitorios
      this.garagens = this.filtros.garagens
      // if (this.garagens === "Tanto ")
      this.banheiros = this.filtros.banheiros
      this.tags = this.filtros.tags
      this.proxCoord = this.filtros.proxCoord
      this.center = this.filtros.center
      this.proxRaio = this.filtros.proxRaio
      this.proximidades = this.filtros.proximidades
      this.valor = this.filtros.valor
      this.area_util = this.filtros.area_util
      // console.log(this.filtros.area_util)
      // this.condominio_nome = this.$route.query.condominio_nome
      // if (this.$route.query.condominio) {
      //   this.condominio_nome = this.$route.query.condominio
      // }
      // this.area_util = this.$route.query.area_util
      this.comodidades = this.filtros.comodidades
      this.caracteres = this.filtros.caracteres
      var tipos = "Todos"
      if (this.tipo_de_imovel && this.tipo_de_imovel !== undefined && this.tipo_de_imovel !== "null") {
        tipos = this.tipo_de_imovel.split(",")
      }
      if (this.cidade === "null" || this.cidade === undefined || this.cidade === "undefined") {
        this.cidade = ""
      }
      var bairros = "Todos"
      if (this.bairro && this.bairro !== undefined && this.bairro !== "null" && this.bairro !== "undefined") {
        bairros = this.bairro.split(",")
      }
      var condominio = ""
      if (this.condominio_nome && this.condominio_nome !== undefined && this.condominio_nome !== "null" && this.condominio_nome !== "undefined") {
        condominio = this.condominio_nome
      }
      var valores = [0, 1000000]
      if (this.valor && this.valor !== undefined && this.valor !== "null" && this.valor !== "undefined") {
        valores = this.valor.split(",")
      }
      if (this.area_util === "null" || this.area_util === undefined || this.area_util === "undefined") {
        this.area_util = "0"
      }
      var maiscomodidades = "Todos"
      if (this.comodidades && this.comodidades !== undefined && this.comodidades !== "null" && this.comodidades !== "undefined") {
        var thiscomodidades = this.comodidades.replace(/.$/, "")
        maiscomodidades = thiscomodidades.split(",")
      }
      // console.log(this.caracteres)
      var arrcaracteres = this.caracteres.split(",")
      // console.log(arrcaracteres)
      var mobiliado = 0
      var semimobiliado = 0
      var exclusivo = false
      var construcao = false
      var mobisemi = false
      arrcaracteres.forEach(element => {
        switch (element) {
          case "Mobiliado":
            mobiliado = 1
            break
          case "Semimobiliado":
            semimobiliado = 2
            break
          case "Em construção":
            construcao = true
            break
          case "Exclusivo":
            exclusivo = true
            break
        }
      })
      if (semimobiliado === 2 && mobiliado === 1) {
        mobisemi = true
      }
      /**
       * Recurso para múltiplos dormitórios
       */
      var dormitoriosx = "Todos"
      if (this.dormitorios && this.dormitorios !== undefined && this.dormitorios !== "null" && this.dormitorios !== "undefined") {
        this.dormitorios = this.dormitorios.replace("+", "")
        this.dormitorios = this.dormitorios.replace(" ", "")
        dormitoriosx = this.dormitorios.split(",")
      }
      // console.log("mobiliado " + mobiliado)
      // console.log("semimobiliado " + semimobiliado)
      // console.log("exclusivo " + exclusivo)
      // console.log("construcao " + construcao)
      // console.log("mobiliao e semi " + mobisemi)
      axios.get("jsons/cafeimoveis.json")
        .then((response) => {
          this.imoveis_destaque = response.data
          this.contador_imoveis = this.imoveis_destaque.filter(
            d =>
              (d.contrato === "Compra" || d.contrato === "Compra,Locação") &&
              (!exclusivo || (exclusivo && (d.exclusividade === true))) &&
              (!mobisemi || (mobisemi && (d.mobiliado === 1 || d.mobiliado === 2))) &&
              (mobisemi || (mobiliado === 0 || (d.mobiliado === mobiliado))) &&
              (mobisemi || (semimobiliado === 0 || (d.mobiliado === semimobiliado))) &&
              (!construcao || (construcao && (d.situacao_condominio === "Em construção"))) &&
              ((tipos === "Todos" || tipos.includes(d.subtipo) || tipos.includes(d.tipo)) ||
              (tipos.includes("Casa") && (d.subtipo === "Casa" || d.subtipo === "Sobrado")) ||
              (tipos.includes("Apartamento") && (
                d.subtipo === "Cobertura" ||
                d.subtipo === "Kitnet" ||
                d.subtipo === "Duplex" ||
                d.subtipo === "Triplex" ||
                d.subtipo === "Loft"
              )) ||
              (tipos.includes("Cobertura") && (
                d.subtipo === "Duplex" ||
                d.subtipo === "Triplex"
              )) ||
              (tipos.includes("Duplex") && (
                d.subtipo === "Duplex" ||
                d.observacoes.indexOf("Duplex") !== -1 ||
                d.observacoes.indexOf("duplex") !== -1
              )) ||
              (tipos.includes("Triplex") && (
                d.subtipo === "Triplex" ||
                d.observacoes.indexOf("Triplex") !== -1 ||
                d.observacoes.indexOf("triplex") !== -1
              )) ||
              (tipos.includes("Kitnet/Loft/Studio") && (
                d.subtipo === "Kitnet" ||
                d.subtipo === "Loft" ||
                d.subtipo === "Studio"
              )) ||
              (tipos.includes("Chácara/Sítio") && (
                d.subtipo === "Chácara" ||
                d.subtipo === "Sítio"
              )) ||
              (tipos.includes("Campo/Fazenda") && (
                d.subtipo === "Campo" ||
                d.subtipo === "Fazenda"
              ))) &&
              ((this.cidade === "" || d.endereco_cidade === this.cidade) ||
              (this.cidade === "Santa Maria" && (
                d.endereco_cidade === "Santa Maria" ||
                d.endereco_cidade === "Itaara" ||
                d.endereco_cidade === "Silveira Martins" ||
                d.endereco_cidade === "São Francisco de Assis" ||
                d.endereco_cidade === "São Martinho da Serra"
              )) ||
              (this.cidade === "Balneário Camboriú" && (
                d.endereco_cidade === "Balneário Camboriú" ||
                d.endereco_cidade === "Balneário Piçarras" ||
                d.endereco_cidade === "Itapema" ||
                d.endereco_cidade === "Itajaí"
              ))) &&
              (bairros === "Todos" || bairros.includes(d.endereco_bairro)) &&
              (condominio === "" || d.condominio_nome === condominio) &&
              // d.dormitorios >= parseInt(this.dormitorios) &&
              (dormitoriosx === "Todos" || dormitoriosx.includes(String(d.dormitorios)) || (
                dormitoriosx.includes("5") && d.dormitorios >= 5
              )) &&
              d.garagens >= parseInt(this.garagens) &&
              d.banheiros >= parseInt(this.banheiros) &&
              (
                d.area_privativa >= parseInt(this.area_util) ||
                // d.area_util >= parseInt(this.area_util) ||
                // d.area_total >= parseInt(this.area_util) ||
                (d.subtipo === "Terreno" && (d.terreno_comprimento * d.terreno_largura >= parseInt(this.area_util))) ||
                (
                  d.medida === "ha" && (
                    (d.area_privativa * 10000) >= parseInt(this.area_util) ||
                    // (d.area_util * 10000) >= parseInt(this.area_util) ||
                    // (d.area_total * 10000) >= parseInt(this.area_util) ||
                    ((d.terreno_comprimento * d.terreno_largura) * 10000) >= parseInt(this.area_util)
                  )
                )
              ) &&
              (
                (valores[1] < 1000000 && (d.valor_venda >= valores[0] && d.valor_venda <= valores[1])) ||
                (valores[1] > 999999 && d.valor_venda >= valores[0])
              ) &&
              (
                (maiscomodidades === "Todos" || (d.imovel_comodidades !== null && this.objetivacomodidades((d.imovel_comodidades + "").split(","), maiscomodidades))) ||
                ((d.condominio_comodidades !== "" && d.condominio_comodidades !== null) && this.objetivacomodidades((d.condominio_comodidades + "").split(","), maiscomodidades))
              ) &&
              (this.tags === "" || (this.tags !== "" && d.tags.indexOf(this.tags) !== -1)) &&
              (this.proximidades === "" || (this.proximidades !== "" && this.nasproximidadesProcessa(this.proxRaio, d.latitude, d.longitude, this.proxCoord.lat, this.proxCoord.lng)))
          ).sort(function(a, b) {
            return b.codigo - a.codigo
          })
          // console.log("proximidades" + this.proximidades)
          // console.log("proxRaio" + this.proxRaio)
          // console.log("proxCoord")
          // console.log(this.proxCoord)
          var seqimoveis1 = []
          var seqimoveis2 = []
          // Ordena os destaques na frente
          seqimoveis1 = this.contador_imoveis.filter(d =>
            d.tags.indexOf("destaque") !== -1
          )
          seqimoveis2 = this.contador_imoveis.filter(d =>
            d.tags.indexOf("destaque") === -1
          )
          seqimoveis1 = this.shuffle(seqimoveis1)
          this.contador_imoveis = seqimoveis1.concat(seqimoveis2)
          this.pesquisacidades = this.contador_imoveis.slice(0, this.fimdpage)
          // FIM do ordenando a campanha na frente
          // this.pesquisacidades = this.pesquisacidades.sort(function(a, b) {
          //   return b.codigo - a.codigo
          // }).slice(0, 15)
          var localidades = []
          var allimoveisl = []
          var imoveiscomerciais = ["Sala Comercial", "Casa Comercial", "Loja"]
          this.contador_imoveis.forEach(pin => {
            var valor = "Consulte-nos"
            if (pin.valor_venda > 0) {
              valor = this.formataValor(pin.valor_venda)
            }
            localidades.push(String(pin.latitude + ";" + pin.longitude))
            var supercard = ""
            supercard += "<div class='card-do-mapa'>"
            supercard += "<a href='imovel/" + pin.codigo + "' target='_blank'>"
            supercard += "<div class='imagem-do-mapa'>"
            supercard += "<label class='mapa-card-codigo'><strong>Cod. " + pin.codigo + "</strong></label>"
            if (pin.thumb) {
              supercard += "<img src='" + pin.thumb + "' style='width: 130px;' />"
            } else {
              supercard += "<img src='/img/logo_a_empresa.png' style='width: 100px;padding-top: 30%;' />"
            }
            supercard += "</div>"
            supercard += "<div class='clearfix'></div>"
            supercard += "<h4 class='texto-do-mapa'>"
            supercard += "<label class='mapa-card-tipo'><strong>"
            supercard += this.abrevia(pin.subtipo)
            supercard += "</strong></label>"
            if (pin.dormitorios > 0) {
              supercard += "<label class='mapa-card-dormitorios'>"
              supercard += pin.dormitorios
              if (imoveiscomerciais.indexOf(pin.subtipo) !== -1) {
                supercard += " " + this.icones.dooropen.html
              } else {
                supercard += " " + this.icones.bed.html
              }
              supercard += "</label>"
            }
            if (pin.garagens > 0) {
              supercard += "<label class='mapa-card-garagens'>"
              supercard += pin.garagens + " " + this.icones.car.html
              supercard += "</label>"
            }
            if (pin.area_privativa > 0) {
              supercard += "<label class='mapa-card-garagens'>"
              supercard += Number(pin.area_privativa).toFixed(0) + " m²"
              supercard += "</label>"
            }
            if (pin.subtipo === "Terreno") {
              supercard += "<label class='mapa-card-garagens'>"
              if (pin.terreno_largura > 0) {
                supercard += this.icones.expandalt.html + " " + pin.terreno_largura + " "
                if (pin.terreno_comprimento > 0) {
                  supercard += " x " + pin.terreno_comprimento
                }
                supercard += " m"
              }
              supercard += "</label>"
            }
            supercard += "</h4>"
            supercard += "<br />"
            supercard += "<label class='mapa-card-valor'><strong>"
            supercard += valor
            supercard += "</strong></label>"
            // supercard += "</div>"
            supercard += "</a>"
            supercard += "</div>"
            allimoveisl.push({
              coord: String(pin.latitude + ";" + pin.longitude),
              infos: supercard
            })
          })
          localidades = ([...new Set(localidades)])
          localidades.forEach(local => {
            var position = local.split(";")
            var imview = ""
            allimoveisl.forEach(element => {
              if (element.coord === local) {
                imview += element.infos + "<br>"
              }
            })
            this.addMarker(Number(position[0]), Number(position[1]), imview)
          })
        })
      history.pushState("Object", "Title", "venda?" + "tipo_de_imovel=" + this.filtros.tipo_de_imovel +
        "&cidade=" + this.filtros.cidade +
        "&bairros=" + this.filtros.bairros +
        "&valor=" + this.filtros.valor +
        "&dormitorios=" + this.filtros.dormitorios + "&garagens=" + this.filtros.garagens + "&banheiros=" + this.filtros.banheiros +
        "&area_util=" + this.filtros.area_util +
        "&comodidades=" + this.filtros.comodidades +
        "&tags=" + this.filtros.tags +
        "&proximidades=" + this.filtros.proximidades + this.urlcomplementos
      )
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    shuffle(array) {
      var currentIndex = array.length
      var temporaryValue
      var randomIndex
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }
      return array
    }
  },
  mounted() {
    this.isLoading = true
    this.windowWidth = window.innerWidth
    this.$nextTick(() => window.addEventListener("resize", this.updateWidth))
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth)
  },
  beforeDestroy() { window.onscroll = null }
}
</script>

<style lang="postcss">
/* Mesmos estilos de Empreendimentos */
  #id-paginacao .c-range-slider {
    width: 80%;
  }
  #id-paginacao .c-input {
    display: none;
  }
  .pesquisa-feita {
    position: absolute;
    top: 85px;
    z-index: 999999;
    background-color: white;
    padding: 3px 10px;
    /* border-radius: 10px; */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    /* font-style: italic; */
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    font-weight: bolder;
  }
  .pesquisa-feita label {
    cursor: pointer;
  }
  .mostra-esconde-mapa {
    padding: 6px;
    padding-top: 20%;
    /* border-radius: 50%; */
    /* background-color: #4062d1; */
    background-color: lightgray;
    color: black;
    width: 40x !important;
    cursor: pointer;
    font-size: 25px;
  }
  .botao-ativo {
    background-color: gray !important;
    border-color: gray !important;
    color: white !important;
  }
  .cluster div {
    color: white !important;
  }
  .estilo-toggle-2024 {
    /* border: 1px solid; */
    padding: 3px 1px;
    border-radius: 8px;
    /* background-color: #DDC080 !important; */
    margin: 0 20px !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
</style>
