<template>
  <c-card-wrapper class="property-card" :class="{ horizontal: horizontal }">
    <div class="relative property-card__image-wrapper">
      <!-- <router-link :to="linkregistro" class="flex property-card__image" target="_blank"> -->
      <div v-if="pagina == 'venda' || pagina == 'locacao' || pagina == 'empreendimento'">
        <div class="property-card__image">
          <div class="absolute top-0 left-0 z-10 mt-2 ml-2 property-card__tag-wrapper">
            <!-- <span class="px-2 py-1 mr-1 text-xs text-white rounded property-card__tag bg-blackweek" v-if="tagblack === 'black'">
            Feirão de Imóveis
          </span> -->
            <span class="px-2 py-1 mr-1 text-xs text-white rounded property-card__tag bg-green" v-if="destaque">
              Em destaque
            </span>
            <span class="px-2 py-1 mr-1 text-xs text-white rounded property-card__tag bg-blue"
              v-if="status === 'Em construção'">
              Em construção
            </span>
            <!-- <span class="text-right" v-if="permuta === true">
              <img :src="require(`@/assets/img/Selo-permuta-site.png`)" alt="" width="200">
            </span> -->
            <span class="px-2 py-1 mr-1 text-xs text-white rounded font-weight fs-3property-card__tag bg-orange"
              v-if="permuta === true">
              Aceita Permuta
            </span>
            <!-- <span class="px-2 py-1 mr-1 text-xs text-white rounded property-card__tag bg-star" style="font-size: x-large" v-if="destaque">
              ⭐️
            </span>
            <span class="px-2 py-1 mr-1 text-xs text-white rounded property-card__tag bg-star"
              style="font-size: x-large"
              v-if="status === 'Em construção'">
              🚧
            </span> -->
            <span class="px-2 py-1 mr-1 text-xs text-white rounded property-card__tag bg-green" v-if="mobiliado === 1">
              Mobiliado
            </span>
            <span class="px-2 py-1 mr-1 text-xs text-white rounded property-card__tag bg-green" v-if="mobiliado === 2">
              Semimobiliado
            </span>
          </div>
          <!-- v-if="carnaval22" -->
          <!-- <span class="property-card__tag">
          <img :src="require(`@/assets/img/bloco-de-ofertas.png`)" class="campanha-carnaval" v-if="tagcarnaval" />
        </span> -->
          <!-- <img
          class="object-cover property-card__thumbnail"
          :src="imagem"
          alt="Nome do imóvel"
          v-if="pagina !== 'empreendimento' && imagem !== null"
        /> -->
          <galeria-card
            v-if="pagina !== 'empreendimento' && imagem !== null"
            class="property__views"
            :slides="propertyPhotos"
            :key="1"
            :perPage="1"
            :linkregistro="linkregistro"
            :title="'Visitar página do imóvel'"
          />
          <img class="object-cover property-card__thumbnail logo-cafe" :src="logonovo" alt="Nome do imóvel" v-else />
          <img class="object-cover property-card__thumbnail logo-cafe" :src="logonovo" alt="Nome do imóvel"
            v-if="pagina === 'empreendimento'" />
        </div>
      </div>
      <!-- <router-link :to="linkregistro" v-if="pagina == 'venda' || pagina == 'locacao' || pagina == 'empreendimento'" target="_blank">
        Ver
      </router-link> -->
      <router-link :to="linkregistro" target="_blank" class="flex property-card__image" v-else>
        <div class="absolute top-0 left-0 z-10 mt-2 ml-2 property-card__tag-wrapper">
          <span class="px-2 py-1 mr-1 text-xs text-white rounded property-card__tag bg-pink" v-if="exclusivo">
            Exclusivo
          </span>
        </div>
        <img class="object-cover property-card__thumbnail" :src="imagem" alt="Nome do imóvel"
          v-if="pagina !== 'empreendimento' && imagem !== null" />
        <!-- <galeria-card
          v-if="pagina !== 'empreendimento' && imagem !== null"
          class="property__views"
          :slides="propertyPhotos"
          :key="1"
          :perPage="1"
          :linkregistro="linkregistro"
          :title="'Visitar página do imóvel'"
        /> -->
        <img class="object-cover property-card__thumbnail logo-cafe" :src="require(`@/assets/img/logo_a_empresa.png`)"
          alt="Nome do imóvel" v-else />
        <img class="object-cover property-card__thumbnail logo-cafe" :src="require(`@/assets/img/logo_a_empresa.png`)"
          alt="Nome do imóvel" v-if="pagina === 'empreendimento'" />
      </router-link>
    </div>
    <div class="property-card__content">
      <div class="p-4 property-card__info">
        <div class="flex justify-between">
          <span class="block text-xs text-primary-500" v-if="subtipo && !nome">
            <!-- <label>Permuta: {{ permuta }}</label> -->
            <div v-if="pagina === 'venda'"><strong>Venda: </strong>{{ subtipo }}</div>
            <div v-if="pagina === 'locacao'"><strong>Locação: </strong>{{ subtipo }}</div>
            <div v-if="pagina === 'imovel'"><strong>{{ termocontrato(contrato) }}: </strong>{{ subtipo }}</div>
            <div v-if="pagina === 'home'"><strong>{{ termocontrato(contrato) }}: </strong>{{ subtipo }}</div>
            <!-- <div v-else><strong>Venda/Aluguel: </strong>{{ subtipo }}</div> -->
          </span>
          <span class="block text-lg text-primary-500" v-if="nome">
            <h2><strong>{{ nome }}</strong></h2>
          </span>
          <span class="text-xs" v-if="codigo">
            Cód. {{ codigo }}
          </span>
        </div>
        <router-link :to="linkregistro" target="_blank">
          <h3 v-if="bairro" class="font-medium">{{ bairro.replace("Nossa Senhora", "N. Sra.") }}</h3>
          <h3 v-if="cidade && pagina === 'homesc'" class="font-small">{{ cidade.replace("Nossa Senhora", "N. Sra.") }}</h3>
          <h4 v-if="estado && pagina === 'homesc'" class="font-small">{{ estado }}</h4>
          <!-- <h4 class="font-small">{{ posicao_solar }}</h4>
          <h5 class="font-small">{{ comodidades }}</h5> -->
          <span class="text-xs text-gray-600" v-if="propertyData.quantidade_imoveis">
            {{ propertyData.quantidade_imoveis }} imóveis disponíveis
          </span>
          <!-- <span class="block text-xs" v-if="endereco">
            {{ endereco }}
          </span> -->
          <!-- EMPREENDIMENTOS NO CARD -->
          <!-- <span
            class="block text-xs text-gray-600"
            v-if="empreendimento"
          >
            {{ empreendimento }}
          </span> -->
          <span class="block text-xs text-gray-600" v-else>
            <br>
          </span>
        </router-link>

        <ul class="pt-4 property-card__features">
          <li class="property-card__feature" v-if="dormitorios">
            <span>{{ dormitorios }}</span>
            <span v-if="suites > 0">({{ suites }})</span>
            <font-awesome-icon :icon="retornacasose(subtipo).icon" />
          </li>
          <li class="property-card__feature" v-if="mais_dormitorios > 0 && mais_dormitorios === menos_dormitorios">
            <span>{{ mais_dormitorios }}</span>
            <font-awesome-icon icon="bed" />
          </li>
          <li class="property-card__feature" v-if="mais_dormitorios > 0 && mais_dormitorios !== menos_dormitorios">
            <span v-if="menos_dormitorios > 0">{{ menos_dormitorios }} a {{ mais_dormitorios }}</span>
            <span v-else>{{ mais_dormitorios }}</span>
            <font-awesome-icon icon="bed" />
          </li>
          <li class="property-card__feature" v-if="vagas">
            <span>{{ vagas }}</span>
            <font-awesome-icon icon="car" />
          </li>
          <li class="property-card__feature" v-if="mais_garagens > 0 && mais_garagens === menos_garagens">
            <span>{{ mais_garagens }}</span>
            <font-awesome-icon icon="car" />
          </li>
          <li class="property-card__feature" v-if="mais_garagens > 0 && mais_garagens !== menos_garagens">
            <span>{{ menos_garagens }} a {{ mais_garagens }}</span>
            <font-awesome-icon icon="car" />
          </li>
          <!-- <li class="property-card__feature">
            <span>{{ banheiros }}</span>
            <font-awesome-icon icon="shower" />
          </li> -->
          <li class="property-card__feature" v-if="area">
            <font-awesome-icon icon="expand-alt" />
            <span> {{ Number(area_impressa).toFixed(1) }}</span>
            <strong>{{ areamedidade }}</strong>
          </li>
          <li class="property-card__feature" v-else-if="subtipo === 'Terreno' && terreno_largura > 0">
            <font-awesome-icon icon="expand-alt" />
            <span> {{ Number(terreno_largura).toFixed(1) }}</span>
            <span v-if="terreno_comprimento > 0">x {{ Number(terreno_comprimento).toFixed(1) }}</span>
            <strong>m</strong>
          </li>
          <li class="property-card__feature" v-else-if="maior_area > 0 && maior_area === menor_area">
            <span>{{ maior_area }}</span>
            <strong>m²</strong>
          </li>
          <li class="property-card__feature" v-else-if="maior_area > 0 && maior_area !== menor_area">
            <span>{{ menor_area }} a {{ maior_area }}</span>
            <strong>m²</strong>
          </li>
          <li class="property-card__feature" v-else>
            <br>
          </li>
          <li class="property-card__feature"
            v-if="subtipo === 'Terreno' && terreno_largura > 0 && terreno_comprimento > 0">
            <font-awesome-icon icon="expand-alt" />
            <span> {{ Number(terreno_largura * terreno_comprimento).toFixed(1) }}</span>
            <strong>{{ areamedidade }}</strong>
          </li>
        </ul>
        <div v-if="nome">
          <p class="mt-4 text-xs" v-if="observacoes">
            {{ descricaoempreendimento }}
            <span v-if="show === false">
              <button style="font-weight: bolder" v-on:click="show = !show" v-if="observacoes.length > 457">
                (...) Ver Mais
              </button>
            </span>
            <transition name="fade" v-if="show">
              <span>{{ vendomais }}</span>
            </transition>
          </p>
          <p class="mt-4 text-xs" v-else>
            Mais informações deste empreendimento e unidades disponíveis, faça contato com nossa equipe de vendas.
          </p>
          <label class="mt-4 text-xs quantos-imoveis" v-if="quantos_imoveis > 0">Número de Imóveis: <strong>{{
            quantos_imoveis
          }}</strong></label>
          <label class="mt-4 text-xs quantos-imoveis" v-else>Sem Imóveis cadastrados</label>
        </div>
      </div>
      <div
        class="flex items-center justify-between px-4 py-2 text-sm border-t-2 border-gray-300 border-solid property-card__footer">
        <div v-if="pagina === 'empreendimento'">
          <strong class="flex-1 font-medium">{{ intervaloValores }}</strong>
        </div>
        <div v-else>
          <strong class="flex-1 font-medium">{{ propertyPrice }}</strong>
        </div>
        <router-link class="text-xs c-button bt-ver-imovel" :to="linkregistro" target="_blank">
          Ver {{ horizontal? "imóveis": "imóvel" }}
        </router-link>
      </div>
    </div>
  </c-card-wrapper>
</template>

<script>
// import CButton from "./UI/CButton.vue"
import CCardWrapper from "./UI/CCardWrapper.vue"
import GaleriaCard from "../components/GaleriaCard.vue"
export default {
  components: {
    GaleriaCard,
    CCardWrapper
    // CButton
  },
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    pagina: { type: String },
    propertyPhotos: { type: Array },
    contrato: { type: String },
    codigo: { type: [String, Number] },
    nome: { type: String },
    tipo: { type: String },
    subtipo: { type: String },
    imagem: { type: String },
    bairro: { type: String },
    cidade: { type: String },
    estado: { type: String },
    endereco_cidade: { type: String },
    endereco: { type: String },
    empreendimento: { type: String },
    posicao_solar: { type: String },
    comodidades: { type: String },
    observacoes: { type: String },
    dormitorios: { default: 0 },
    suites: { default: 0 },
    vagas: { default: 0 },
    quantos_imoveis: { default: 0 },
    // banheiros: { default: 0 },
    area: {},
    terreno_largura: { type: Number },
    terreno_comprimento: { type: Number },
    valor_venda: { type: Number },
    valor_locacao: { type: Number },
    maior_valor_venda: { type: [String, Number] },
    menor_valor_venda: { type: [String, Number] },
    maior_valor_locacao: { type: [String, Number] },
    menor_valor_locacao: { type: [String, Number] },
    mais_dormitorios: { type: [String, Number] },
    menos_dormitorios: { type: [String, Number] },
    mais_garagens: { type: [String, Number] },
    menos_garagens: { type: [String, Number] },
    maior_area: { type: [String, Number] },
    menor_area: { type: [String, Number] },
    exclusivo: {
      type: Boolean,
      default: false
    },
    tags: {},
    propertyData: {
      type: Object,
      default() {
        return {
          tipo: "Apartamento",
          nome: "Bairro Centro",
          endereco: "Rua Marina Prezzi, 65",
          empreendimento: "Resedencial Pio X",
          dormitorios: 3,
          garagens: 3,
          area_t: 2323,
          preco: [20000000],
          codigo: 2323
        }
      }
    },
    status: { type: String },
    permuta: { type: Boolean },
    mobiliado: { type: [Number, String] },
    area_total: { type: [Number, String] },
    area_util: { type: [Number, String] },
    area_privativa: { type: [Number, String] }
  },
  data: function () {
    return {
      tagblack: "",
      tagcarnaval: false,
      cond: this.subtipo,
      show: false,
      windowWidth: 0,
      tiposcomerciais: ["Casa Comercial", "Sala Comercial", "Loja", "Predio Comercial"],
      tiposresidenciais: ["Casa", "Apartamento", "Cobertura", "Sobrado", "Kitnet", "Loft", "Triplex", "Duplex"],
      tiposrurais: ["Sítio", "Fazenda", "Chácara"],
      tiposterrenos: ["Terreno"],
      areamedidade: "m²",
      area_impressa: "",
      logonovo: require("../assets/img/logos/2.png"),
      destaque: false
    }
  },
  computed: {
    linkregistro() {
      var retorno
      if (this.pagina === "empreendimento") {
        if (this.quantos_imoveis > 0) {
          retorno = "empreendimento?nome=" + this.nome
        } else {
          retorno = "/"
        }
      } else {
        var extras = ""
        var contrato = "venda"
        if (this.contrato !== "") {
          if (this.contrato === "Compra") {
            contrato = "venda"
          } else if (this.contrato === "Locação") {
            contrato = "aluguel"
          } else {
            contrato = "venda-e-aluguel"
          }
          extras += contrato
        }
        if (this.subtipo !== "") {
          extras += "-" + this.subtipo
        }
        if (this.dormitorios > 0 && this.dormitorios !== null) {
          extras += "-" + this.dormitorios + "-" + this.retornacasose(this.subtipo).label
          if (this.dormitorios > 1) {
            extras += "s"
          }
        }
        if (this.vagas > 0 && this.vagas !== null) {
          extras += "-" + this.vagas + "-" + "vaga"
          if (this.vagas > 1) {
            extras += "s"
          }
        }
        if (this.tiposterrenos.indexOf(this.subtipo) || this.tiposrurais.indexOf(this.subtipo)) {
          if (this.area_total > 0) {
            if (Number(this.area_total > 10000)) {
              extras += Number((this.area_total / 10000)) + "ha"
            } else {
              extras += "-" + this.area_total + "m2"
            }
          }
        }
        if (this.empreendimento !== "" && this.empreendimento !== null) {
          extras += "-" + this.empreendimento.replace("/", "_")
        }
        if (this.bairro !== "") {
          extras += "-" + this.bairro
        }
        if (this.cidade !== "") {
          extras += "-" + this.cidade
        }
        if (this.estado !== "") {
          extras += "-" + this.estado.replace("Rio Grande do Sul", "rs").replace("Santa Catarina", "sc").replace("Ceará", "ce")
        }
        // if (this.tags !== "" && this.tags.indexOf("black") !== -1) {
        //   extras += "-blackweek"
        // }
        if (this.tags !== "") {
          extras += "-" + this.tags.replace("alto padrao", "altopadrao-ap").replace(" ", "_").replace(",", "-")
        }
        retorno = "/imovel/" + this.codigo + "/" + extras
      }
      if (this.pagina !== "empreendimento") {
        retorno = this.amigavel(retorno)
      }
      if (window.location.href.includes("utm_")) {
        // (this.$route.query) ? retorno += "&" : retorno += "?"
        retorno += "?registros=cafe"
        if (this.$route.query.utm_source) {
          retorno += "&utm_source=" + this.$route.query.utm_source
        }
        if (this.$route.query.utm_medium) {
          retorno += "&utm_medium=" + this.$route.query.utm_medium
        }
        if (this.$route.query.utm_campaign) {
          retorno += "&utm_campaign=" + this.$route.query.utm_campaign
        }
        if (this.$route.query.utm_id) {
          retorno += "&utm_id=" + this.$route.query.utm_id
        }
        if (this.$route.query.utm_term) {
          retorno += "&utm_term=" + this.$route.query.utm_term
        }
        if (this.$route.query.utm_content) {
          retorno += "&utm_content=" + this.$route.query.utm_content
        }
      }
      return retorno
    },
    descricaoempreendimento() {
      return this.observacoes.substr(0, 457)
    },
    vendomais() {
      return this.observacoes.substr(457, 1000)
    },
    propertyPrice() {
      var valor
      var retorno
      const formatter = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
      if (this.pagina) {
        if ((this.pagina === "venda" || this.pagina === "imovel" || this.pagina === "home") && this.valor_venda && this.valor_venda > 0 && this.valor_venda !== null) {
          valor = this.valor_venda
        } else {
          if ((this.pagina === "locacao" || this.pagina === "imovel" || this.pagina === "home") && this.valor_locacao && this.valor_locacao > 0 && this.valor_locacao !== null) {
            valor = this.valor_locacao
          } else {
            valor = 0
          }
        }
      } else {
        if (this.valor_venda && this.valor_venda > 0 && this.valor_venda !== null) {
          valor = this.valor_venda
        } else {
          if (this.valor_locacao && this.valor_locacao > 0 && this.valor_locacao !== null) {
            valor = this.valor_locacao
          } else {
            valor = 0
          }
        }
      }
      if (valor > 0) {
        retorno = formatter.format(valor)
      } else {
        retorno = "Consulte-nos"
      }
      return retorno
    },
    intervaloValores() {
      var retorno
      // var contrato
      const formatter = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
      if (this.maior_valor_venda > 0) {
        // contrato = "Venda: "
        // console.log("venda")
        if ((this.maior_valor_venda > 0 && this.menor_valor_venda > 0) && (this.maior_valor_venda !== this.menor_valor_venda)) {
          retorno = `${formatter.format(this.menor_valor_venda)} a ${formatter.format(this.maior_valor_venda)}`
        }
        if (this.maior_valor_venda > 0 && this.maior_valor_venda === this.menor_valor_venda) {
          retorno = `${formatter.format(this.maior_valor_venda)}`
        }
        if (this.maior_valor_venda === 0) {
          retorno = "Consulte-nos"
          // contrato = ""
        }
        if (retorno === undefined) {
          retorno = "Consulte-nos"
          // contrato = ""
        }
      } else {
        // contrato = "Locação: "
        // console.log("locação " + this.nome + " - " + this.maior_valor_locacao + " == " + this.menor_valor_locacao)
        if ((this.maior_valor_locacao > 0 && this.menor_valor_locacao > 0) && (this.maior_valor_locacao !== this.menor_valor_locacao)) {
          retorno = `${formatter.format(this.menor_valor_locacao)} a ${formatter.format(this.maior_valor_locacao)}`
        }
        if (this.maior_valor_locacao > 0 && this.maior_valor_locacao === this.menor_valor_locacao) {
          retorno = `${formatter.format(this.maior_valor_locacao)}`
        }
        if (this.maior_valor_locacao === 0) {
          retorno = "Consulte-nos"
          // contrato = ""
        }
      }
      // console.log(retorno)
      return retorno
    },
    propertyFeatures() {
      const { dormitorios, garagens, area } = this.propertyData
      const features = {}
      const featureArray = [dormitorios, garagens, area]

      featureArray.forEach((feature, index) => {
        let value = feature
        const keys = ["dormitorios", "garagens", "area"]

        if (typeof feature === "object") {
          value = `${feature[0]} a ${feature[1]}`
        }

        features[keys[index]] = value
      })
      return features
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
  },
  methods: {
    updateWidth({ target }) {
      this.windowWidth = target.innerWidth
    },
    amigavel: function (title) {
      var slug = ""
      // Change to lower case
      var titleLower = title.toLowerCase()
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e")
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a")
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o")
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u")
      // Letter "i"
      slug = slug.replace(/i|í|ì|ĩ|ï|î/gi, "i")
      // Letter "d"
      slug = slug.replace(/đ/gi, "d")
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "")
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-")
      slug = slug.replace("ç", "c")
      return slug
    },
    termocontrato(contrato) {
      var retorno
      switch (contrato) {
        case "Compra":
          retorno = "Venda"
          break
        case "Locação": retorno = "Aluguel"
          break
        case "Compra,Locação": retorno = "Venda, Aluguel"
          break
        default: retorno = contrato
          break
      }
      return retorno
    },
    retornacasose(tipo) {
      var retorno = {
        icon: "bed",
        label: "Dormitório"
      }
      var arrtiposcomerciais = ["Sala Comercial", "Casa Comercial", "Loja"]
      if (arrtiposcomerciais.indexOf(tipo) !== -1) {
        retorno = {
          icon: "door-open",
          label: "Sala"
        }
      }
      return retorno
    }
  },
  created() {
    // if (this.exclusivo) {
    //   console.log(this.exclusivo)
    //   this.tags = [
    //     { nome: "exclusivo", cor: "pink" }
    //   ]
    // }
    // console.log("tags: " + this.tags)
    if (this.tags !== "" && this.tags.indexOf("black") !== -1) {
      this.tagblack = "black"
    }
    if (this.tags !== "" && this.tags.indexOf("carnaval22") !== -1) {
      this.tagcarnaval = true
    }
    if (this.tags !== "" && this.tags.indexOf("destaque") !== -1) {
      this.destaque = true
    }
    if (this.area_privativa > 0) {
      if (Number(this.area_privativa > 10000)) {
        this.area_impressa = Number((this.area_privativa / 10000))
        this.areamedidade = "ha"
      } else {
        this.area_impressa = Number(this.area_privativa)
        this.areamedidade = "m²"
      }
    } else if (this.area_util > 0) {
      if (Number(this.area_util > 10000)) {
        this.area_impressa = Number((this.area_util / 10000))
        this.areamedidade = "ha"
      } else {
        this.area_impressa = Number(this.area_util)
        this.areamedidade = "m²"
      }
    } else if (this.area_total > 0) {
      if (Number(this.area_total > 10000)) {
        this.area_impressa = Number((this.area_total / 10000))
        this.areamedidade = "ha"
      } else {
        this.area_impressa = Number(this.area_total)
        this.areamedidade = "m²"
      }
    } else if (this.area > 0) {
      if (Number(this.area > 10000)) {
        this.area_impressa = Number((this.area / 10000))
        this.areamedidade = "ha"
      } else {
        this.area_impressa = Number(this.area)
        this.areamedidade = "m²"
      }
    }
  }
}
</script>

<style lang="postcss">
.pb-4 {
  width: 100%;
}

.property-card {

  /* Os de construção */
  .bg-orange {
    background-color: orange !important;
  }

  /* Os mobiliados */
  .bg-blue {
    background-color: blue !important;
  }

  /* Os Semi-mobiliados */
  .bg-green {
    background-color: green !important;
  }

  /* Os Blackweek */
  .bg-blackweek {
    background-color: #00a0de !important;
    color: white;
    /* text-transform: uppercase !important; */
    font-weight: bolder !important;
  }

  @apply border-2 border-solid;
  transition: border 100ms linear;

  &:hover {
    @apply border-primary-500;
  }

  a {
    user-select: text;
  }

  &__image {
    height: 300px;
    width: 100% !important;
    overflow: hidden;

    &-buttons {
      .c-button {
        @apply w-10 h-10 p-0;
        opacity: 0.75;
        transition: opacity 150ms linear;

        &:hover {
          opacity: 1;
          @apply text-primary-500;
        }

        &:focus {
          opacity: 1;
          @apply text-red-500 bg-white border-gray-400;
        }
      }
    }
  }

  &__thumbnail {
    max-width: none;
    width: 100%;
    transition: 100ms transform ease-in-out;
  }

  &__features {
    li+li {
      @apply pl-4 border-l-2 border-gray-300 border-solid;
    }

    ^&__feature {
      @apply inline-block pr-4 text-xs font-medium text-gray-600;

      span+* {
        @apply ml-1;
      }
    }
  }

  &.horizontal {
    @apply flex mb-4;

    @media (max-width: 1024px) {
      @apply flex-wrap;
    }

    ^&__image {
      max-height: 280px !important;
      width: auto !important;
      max-width: 260px !important;

      @media (max-width: 1024px) {
        @apply w-full;
        max-width: 100% !important;
      }
    }

    ^&__content {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}

.logo-cafe {
  transform: scale(0.7);
  object-fit: fill !important;
  /* -webkit-filter: drop-shadow(15px 15px 10px gray);
    filter: drop-shadow(15px 15px 10px gray); */
}

.quantos-imoveis {
  margin-bottom: 5px !important;
}

.campanha-carnaval {
  /* transform: rotate(-45deg) !important; */
  width: 60%;
  position: absolute;
  left: 50%;
  top: -5%;
  /* margin-left: -25px; */
}
</style>
