<template>
  <div class="property-search" v-bind:contrato="'locacao'">
    <main-header />
    <c-section
      class="flex w-full p-0 property-search__wrapper"
      :container="false"
    >
      <div class="hidden w-2/5 lg:block" v-show="mostrarmapa">
        <gmap-map
          :center="center"
          :zoom="options.zoom"
          style="width:100%; height: 100%;"
        >
          <gmap-cluster :zoomOnClick="true">
            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
            </gmap-info-window>
            <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                @click="toggleInfoWindow(m)"
                :icon="'/icones/marcador_FFF.png'"
            ></gmap-marker>
          </gmap-cluster>
        </gmap-map>
      </div>
      <div :class="classlista">
        <transition name="fade-slide" mode="out-in">
          <aside
            class="property-search__drawer v-group"
            v-if="showFilters"
            :key="1"
          >
            <property-search-filters
              v-bind:contrato="'locacao'"
              @close="showFilters = !showFilters"
              @pesquisar="pesquisandoimoveis"
              v-bind:imoveis_destaque="imoveis_destaque"
              v-on:filtros="addfiltros"
              :filtros="filtros"
            />
          </aside>

          <section
            class="py-0 property-search__drawer"
            v-if="!showFilters"
            :key="2"
          >
            <div
              class="sticky z-20 flex-col px-4 text-center bg-white property-search__title lg:flex-row lg:text-left"
            >
              <div class="hidden mb-2 md:w-1/5 xl:mr-auto md:mb-0 lg:block">
                <h3 class="text-sm font-medium md:text-lg md:font-normal">Mostrar Mapa</h3>
                <toggle-button
                  @change="inputmapa"
                  :value="mostrarmapa"
                  color="#1F362E"
                />
              </div>
              <div class="mb-2 md:w-1/2 xl:mr-auto md:mb-0">
                <h3 class="text-sm font-medium md:text-lg md:font-normal">
                  Imóveis em
                  <span class="text-primary-500" v-if="cidade">{{ this.cidade }} e região</span>
                  <span class="text-primary-500" v-else>Santa Maria e região</span>
                </h3>
                <small>{{ this.contador_imoveis.length }} encontrados</small>
              </div>
              <div class="flex justify-center w-full md:w-1/2">
                <c-select
                  placeholder="Ordenar por"
                  :options="formOptions.ordenacao"
                  class="w-1/2 mb-0 mr-2 text-xs"
                  v-model="ordenador"
                  @input="ordenarresultados"
                ></c-select>
                <c-button
                  class="w-1/2 text-xs rounded-lg primary"
                  @click="showFilters = !showFilters"
                  icon="sliders-h"
                >
                  Filtros <strong style="font-size: 15px" v-if="contafiltros > 0">  ({{ contafiltros }})</strong>
                </c-button>
              </div>
            </div>
            <div class="flex flex-wrap px-2">
              <div :class="gridClasses" v-for="item in pesquisacidades" :key="item.codigo">
                <property-card
                  v-bind:codigo="item.codigo"
                  v-bind:pagina="'locacao'"
                  v-bind:contrato="item.contrato"
                  v-bind:tipo="item.tipo"
                  v-bind:subtipo="item.subtipo"
                  v-bind:imagem="item.imagem"
                  v-bind:bairro="item.endereco_bairro"
                  v-bind:cidade="item.endereco_cidade"
                  v-bind:estado="item.endereco_estado"
                  v-bind:endereco_cidade="item.endereco_cidade"
                  v-bind:endereco="item.endereco_logradouro"
                  v-bind:empreendimento="item.condominio_nome"
                  v-bind:dormitorios="item.dormitorios"
                  v-bind:suites="item.suites"
                  v-bind:vagas="item.garagens"
                  v-bind:area="item.area_privativa"
                  v-bind:valor_venda="item.valor_venda"
                  v-bind:valor_locacao="item.valor_locacao"
                  v-bind:exclusivo="item.exclusividade"
                  v-bind:posicao_solar="item.posicao_solar"
                  v-bind:comodidades="item.imovel_comodidades"
                  v-bind:terreno_largura="item.terreno_largura"
                  v-bind:terreno_comprimento="item.terreno_comprimento"
                  v-bind:status="item.status"
                  v-bind:mobiliado="item.mobiliado"
                  v-bind:tags="item.tags"
                  v-bind:propertyPhotos="item.galeria_thumb"
                />
              </div>
              <infinite-loading @infinite="paginando" spinner="spiral">
                <div slot="spinner">Loading...</div>
                <div slot="no-more">Fim da listagem</div>
                <div slot="no-results">Sem resultados</div>
              </infinite-loading>
              <!-- v-bind:banheiros="item.banheiros" -->
            </div>
          </section>
        </transition>
      </div>
      <!-- <div class="hidden w-2/5 lg:block">
        <google-map :lat="-29.6914" :lng="-53.8008">
          <template slot-scope="{ google, map }">
            <GoogleMapMarker
              v-for="marker in markers"
              :key="marker.id"
              :marker="marker"
              :google="google"
              :map="map"
            />
          </template>
        </google-map>
      </div> -->
    </c-section>
  </div>
</template>

<script>
import PropertySearchFilters from "../components/PropertySearchFilters.vue"
// import GoogleMap from "../components/GoogleMap.vue"
// import GoogleMapMarker from "../components/GoogleMapMarker.vue"
import PropertyCard from "../components/PropertyCard.vue"
import CSection from "../components/UI/CSection.vue"
import CButton from "../components/UI/CButton.vue"
import CSelect from "../components/UI/CSelect.vue"
import MainHeader from "../components/MainHeader.vue"
import EstadoCidadeMixin from "../mixins/EstadoCidadeMixin"
import FormOptionsMixin from "../mixins/FormOptionsMixin"
import InfiniteLoading from "vue-infinite-loading"
// import CRangeSlider from "../components/UI/CRangeSlider.vue"
import { icon } from "@fortawesome/fontawesome-svg-core"
import axios from "axios"
// import json from "json"

export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "Pesquisa de Imóveis para alugar",
    meta: [
      {
        name: "description",
        content: "Café Inteligência Imobiliária atua em Santa Maria/RS e região, imóveis santa maria, Imobiliarias santa maria, Alugar, aluguel de imóveis"
      }
    ]
  },
  components: {
    MainHeader,
    CSelect,
    CButton,
    CSection,
    PropertyCard,
    InfiniteLoading,
    // CRangeSlider,
    // GoogleMap,
    // GoogleMapMarker,
    PropertySearchFilters
  },
  mixins: [EstadoCidadeMixin, FormOptionsMixin],
  data() {
    return {
      mostrarmapa: true,
      classlista: "property-search__scrollable lg:w-3/5 sm:w-full",
      carregandoitens: true,
      nomeCliente: "Meu nome X",
      showFilters: false,
      windowWidth: 0,
      gridClasses: "px-2 pb-4 xl:w-1/2 md:w-1/2 xs:w-1/1",
      imoveis_destaque: [],
      cidadesFromAPI: [],
      bairrosFromAPI: [],
      pesquisacidades: [],
      contador_imoveis: [],
      cidade: {},
      bairro: {},
      dormitorios: {},
      garagens: {},
      banheiros: {},
      valor: {},
      area_util: {},
      // orientacao_solar: {},
      comodidades: {},
      condominio_nome: {},
      ordenador: [],
      paginas: [],
      page: 1,
      initpage: 1,
      fimdpage: 15,
      busy: false,
      caracteres: [],
      ordenacao: [
        {
          id: "mais_recentes",
          label: "Mais recentes"
        },
        {
          id: "maior_preco",
          label: "Maior preço"
        },
        {
          id: "menor_preco",
          label: "Menor preço"
        }
      ],
      center: { lat: -29.6914, lng: -53.8008 },
      places: [],
      markers: [],
      currentPlace: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35
        },
        width: 380
      },
      options: {
        zoom: 12
      },
      icones: {
        bed: icon({ prefix: "fas", iconName: "bed" }),
        car: icon({ prefix: "fas", iconName: "car" }),
        expandalt: icon({ prefix: "fas", iconName: "expand-alt" }),
        dooropen: icon({ prefix: "fas", iconName: "door-open" })
      },
      pesquisafeita: "",
      filtrosativos: false,
      filtros: [],
      contafiltros: 0,
      textopesquisa: "Pesquise aqui:"
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth < 1024
    },
    contpaginas() {
      var contagem = this.contador_imoveis.length / 30
      if (parseInt(contagem) < contagem) {
        return parseInt(contagem) + 1
      } else {
        return contagem
      }
    },
    urlcomplementos() {
      var retorno = ""
      if (window.location.href.includes("utm_")) {
        // (this.$route.query) ? retorno += "&" : retorno += "?"
        // retorno += "?registros=cafe"
        if (this.$route.query.utm_source) {
          retorno += "&utm_source=" + this.$route.query.utm_source
        }
        if (this.$route.query.utm_medium) {
          retorno += "&utm_medium=" + this.$route.query.utm_medium
        }
        if (this.$route.query.utm_campaign) {
          retorno += "&utm_campaign=" + this.$route.query.utm_campaign
        }
        if (this.$route.query.utm_id) {
          retorno += "&utm_id=" + this.$route.query.utm_id
        }
        if (this.$route.query.utm_term) {
          retorno += "&utm_term=" + this.$route.query.utm_term
        }
        if (this.$route.query.utm_content) {
          retorno += "&utm_content=" + this.$route.query.utm_content
        }
        return retorno
      } else {
        return ""
      }
    }
  },
  created() {
    this.tipo_de_imovel = this.$route.query.tipo_de_imovel
    this.condominio_nome = this.$route.query.condominio_nome
    this.cidade = this.$route.query.cidade
    this.bairro = this.$route.query.bairros
    this.dormitorios = this.$route.query.dormitorios
    this.garagens = this.$route.query.garagens
    this.banheiros = this.$route.query.banheiros
    this.valor = this.$route.query.valor
    this.area_util = this.$route.query.area_util
    // this.orientacao_solar = this.$route.query.orientacao_solar
    this.comodidades = this.$route.query.comodidades
    // console.log("tp_imo: " + this.tipo_de_imovel)
    // console.log("cidade: " + this.cidade)
    // console.log("bairro: " + this.bairro)
    // console.log("dormit: " + this.dormitorios)
    // console.log("garage: " + this.garagens)
    // console.log("banhei: " + this.banheiros)
    // console.log("area_u: " + this.area_util)
    // console.log("orie_s: " + this.orientacao_solar)
    // Processa Tipos de Imóvel
    var itens = 0
    var arrrec = this.$route.query
    var textopesquisa2 = "Pesquisa: \n"
    Object.keys(arrrec).forEach(function(item) {
      // var arrtipos = ["0", 0, "0+", "0,1000000,"]
      if (
        arrrec[item] !== "" &&
        arrrec[item] !== "0" &&
        arrrec[item] !== "0+" &&
        arrrec[item] !== "0,1000000," &&
        arrrec[item] !== 0
      ) {
        itens += 1
        var campo = item
        switch (item) {
          case "tipo_de_imovel": campo = "tipo"
            break
          case "dormitorios": campo = "cômodos"
            break
        }
        textopesquisa2 += campo + ": " + arrrec[item] + " \n"
      }
      // console.log(item + " = " + arrrec[item])
    })
    this.textopesquisa = textopesquisa2
    this.contafiltros = itens
    var tipos = "Todos"
    if (this.tipo_de_imovel && this.tipo_de_imovel !== undefined && this.tipo_de_imovel !== "null") {
      tipos = this.tipo_de_imovel.split(",")
    }
    // Processa Cidade recebida
    if (this.cidade === "null" || this.cidade === undefined || this.cidade === "undefined") {
      this.cidade = ""
    }
    // Processa Bairros
    var bairros = "Todos"
    if (this.bairro && this.bairro !== undefined && this.bairro !== "null" && this.bairro !== "undefined") {
      bairros = this.bairro.split(",")
    }
    var condominio = ""
    if (this.condominio_nome && this.condominio_nome !== undefined && this.condominio_nome !== "null" && this.condominio_nome !== "undefined") {
      condominio = this.condominio_nome
    }
    // Processa Dormitorios
    // if (this.dormitorios === "null" || this.dormitorios === undefined || this.dormitorios === "undefined") {
    //   this.dormitorios = "0"
    // }
    // Processa Garagens
    if (this.garagens === "null" || this.garagens === undefined || this.garagens === "undefined") {
      this.garagens = "0"
    }
    // Processa Banheiros
    if (this.banheiros === "null" || this.banheiros === undefined || this.banheiros === "undefined") {
      this.banheiros = "0"
    }
    // Valores
    var valores = [0, 950000]
    if (this.valor && this.valor !== undefined && this.valor !== "null" && this.valor !== "undefined") {
      valores = this.valor.split(",")
    }
    // Área Útil Privativa
    if (this.area_util === "null" || this.area_util === undefined || this.area_util === "undefined") {
      this.area_util = "0"
    }
    // Processa Posição Solar
    // var posisolar = "Todos"
    // if (this.orientacao_solar && this.orientacao_solar !== undefined && this.orientacao_solar !== "null" && this.orientacao_solar !== "undefined") {
    //   posisolar = this.orientacao_solar.split(",")
    // }
    // Processa Bairros
    var maiscomodidades = "Todos"
    // var testecomodidades = "Sacada,Closet"
    // testecomodidades = testecomodidades.split(",")
    if (this.comodidades && this.comodidades !== undefined && this.comodidades !== "null" && this.comodidades !== "undefined") {
      var thiscomodidades = this.comodidades.replace(/.$/, "")
      maiscomodidades = thiscomodidades.split(",")
    }
    /**
     * Recurso para múltiplos dormitórios
     */
    var dormitoriosx = "Todos"
    if (this.dormitorios && this.dormitorios !== undefined && this.dormitorios !== "null" && this.dormitorios !== "undefined") {
      this.dormitorios = this.dormitorios.replace("+", "")
      this.dormitorios = this.dormitorios.replace(" ", "")
      dormitoriosx = this.dormitorios.split(",")
    }
    // console.log("A pesquisa: " + maiscomodidades)
    // console.log("Oos testes: " + testecomodidades)
    // console.log(this.objetivacomodidades(testecomodidades, maiscomodidades))
    // console.log("compara arrays " + this.compare(testecomodidades, maiscomodidades))
    // console.log("elementos comuns " + this.verseexiste(testecomodidades, maiscomodidades))
    axios.get("jsons/cafeimoveis.json")
      .then((response) => {
        this.imoveis_destaque = response.data
        this.contador_imoveis = this.imoveis_destaque.filter(
          d =>
            (d.contrato === "Locação" || d.contrato === "Compra,Locação") &&
            (tipos === "Todos" || tipos.includes(d.subtipo) || tipos.includes(d.tipo)) &&
            // (this.cidade === "" || d.endereco_cidade === this.cidade) &&
            ((this.cidade === "" || d.endereco_cidade === this.cidade) ||
              (this.cidade === "Santa Maria" && (
                d.endereco_cidade === "Santa Maria" ||
                d.endereco_cidade === "Itaara" ||
                d.endereco_cidade === "Silveira Martins" ||
                d.endereco_cidade === "São Francisco de Assis" ||
                d.endereco_cidade === "São Martinho da Serra"
              ))) &&
            (bairros === "Todos" || bairros.includes(d.endereco_bairro)) &&
            (condominio === "" || d.condominio_nome === condominio) &&
            // d.dormitorios >= parseInt(this.dormitorios) &&
            (dormitoriosx === "Todos" || dormitoriosx.includes(String(d.dormitorios)) || (
              dormitoriosx.includes("5") && d.dormitorios >= 5
            )) &&
            d.garagens >= parseInt(this.garagens) &&
            d.banheiros >= parseInt(this.banheiros) &&
            d.area_privativa >= parseInt(this.area_util) &&
           ((d.valor_locacao >= valores[0] && d.valor_locacao <= valores[1]) || (valores[0] >= 9999 && Number(d.valor_locacao) >= 9999)) &&
            (maiscomodidades === "Todos" || (d.imovel_comodidades != null && this.objetivacomodidades(d.imovel_comodidades.split(","), maiscomodidades)))
        ).sort(function(a, b) {
          return b.codigo - a.codigo
        })
        // console.log(this.imoveis_destaque)
        this.pesquisacidades = this.contador_imoveis.sort(function(a, b) {
          return b.codigo - a.codigo
        }).slice(0, this.fimdpage)
        // this.paginas = this.pesquisacidades.length
        var localidades = []
        var allimoveisl = []
        var imoveiscomerciais = ["Sala Comercial", "Casa Comercial", "Loja"]
        this.contador_imoveis.forEach(pin => {
          var valor = "Consulte-nos"
          if (pin.valor_locacao > 0) {
            valor = this.formataValor(pin.valor_locacao)
          }
          localidades.push(String(pin.latitude + ";" + pin.longitude))
          var supercard = ""
          supercard += "<div class='card-do-mapa'>"
          supercard += "<a href='imovel/" + pin.codigo + "' target='_blank'>"
          supercard += "<div class='imagem-do-mapa'>"
          supercard += "<label class='mapa-card-codigo'><strong>Cod. " + pin.codigo + "</strong></label>"
          if (pin.thumb) {
            supercard += "<img src='" + pin.thumb + "' style='width: 130px;' />"
          } else {
            supercard += "<img src='/img/logo_a_empresa.png' style='width: 100px;' />"
          }
          supercard += "</div>"
          supercard += "<div class='clearfix'></div>"
          // supercard += "<div class='texto-do-mapa'>"
          supercard += "<label class='mapa-card-tipo'><strong>"
          supercard += this.abrevia(pin.subtipo)
          supercard += "</strong></label>"
          // supercard += "<div class='clearfix'></div>"
          // supercard += "<hr style='position: relative; top: 45%'>"
          if (pin.dormitorios > 0) {
            supercard += "<label class='mapa-card-dormitorios'>"
            supercard += pin.dormitorios
            if (imoveiscomerciais.indexOf(pin.subtipo) !== -1) {
              supercard += " " + this.icones.dooropen.html
            } else {
              supercard += " " + this.icones.bed.html
            }
            supercard += "</label>"
          }
          if (pin.garagens > 0) {
            supercard += "<label class='mapa-card-garagens'>"
            supercard += pin.garagens + " " + this.icones.car.html
            supercard += "</label>"
          }
          if (pin.area_privativa > 0) {
            supercard += "<label class='mapa-card-garagens'>"
            supercard += Number(pin.area_privativa).toFixed(0) + " m²"
            supercard += "</label>"
          }
          if (pin.subtipo === "Terreno") {
            supercard += "<label class='mapa-card-garagens'>"
            if (pin.terreno_largura > 0) {
              supercard += this.icones.expandalt.html + " " + pin.terreno_largura + " "
              if (pin.terreno_comprimento > 0) {
                supercard += " x " + pin.terreno_comprimento
              }
              supercard += " m"
            }
            supercard += "</label>"
          }
          // gm-ui-hover-effectgm-ui-hover-effect
          supercard += "<br />"
          supercard += "<label class='mapa-card-valor'><strong>"
          supercard += valor
          supercard += "</strong></label>"
          // supercard += "</div>"
          supercard += "</a>"
          supercard += "</div>"
          allimoveisl.push({
            coord: String(pin.latitude + ";" + pin.longitude),
            infos: supercard
          })
        })
        // console.log(localidades)
        // console.log("tamanho antes: " + localidades.length)
        localidades = ([...new Set(localidades)])
        // console.log("tamanho depois: " + localidades.length)
        localidades.forEach(local => {
          var position = local.split(";")
          // console.log(position[0])
          // console.log(position[1])
          var imview = ""
          allimoveisl.forEach(element => {
            if (element.coord === local) {
              imview += element.infos + "<br>"
            }
          })
          this.addMarker(Number(position[0]), Number(position[1]), imview)
        })
      })
  },
  methods: {
    inputmapa() {
      this.mostrarmapa = !this.mostrarmapa
      if (this.mostrarmapa) {
        this.classlista = "property-search__scrollable lg:w-3/5 sm:w-full"
        this.gridClasses = "px-2 pb-4 xl:w-1/2 md:w-1/2 xs:w-1/1"
        this.iconeabremapa = "caret-left"
        this.textomostramapa = "Ocultar o Mapa"
      } else {
        this.classlista = "property-search__scrollable lg:w-5/5 sm:w-full"
        this.gridClasses = "px-2 pb-4 xl:w-1/4 md:w-1/2 xs:w-1/1"
        this.iconeabremapa = "caret-right"
        this.textomostramapa = "Mostra o Mapa"
      }
    },
    updateWidth({ target }) {
      this.windowWidth = target.innerWidth
    },
    verseexiste(arr1, arr2) {
      arr1.sort()
      arr2.sort()
      var arr3 = []
      for (var i = 0; i <= arr1.length; i++) {
        if (arr2.indexOf(arr1[i]) > -1) {
          arr3.push(arr1[i])
        }
      }
      return arr3
    },
    compare(ar1, ar2) {
      ar1.sort()
      ar2.sort()
      if (ar1.length !== ar2.length) {
        return false
      }
      for (var i = 0; i < ar1.length; i++) {
        if (ar1[i] !== ar2[i]) {
          return false
        }
      }
      return true
    },
    objetivacomodidades(arr1, arr2) {
      // arr1 = Object.values(arr1)
      // arr1.join(",")
      var novoarr = []
      for (let j = 0; j < arr1.length; j++) {
        const element = arr1[j]
        // console.log(element)
        const el = element.trim()
        novoarr.push(el)
      }
      novoarr = novoarr.join(",")
      var arr3 = this.verseexiste(novoarr.split(","), arr2)
      return this.compare(arr2, arr3)
    },
    ordenarresultados() {
      // console.log(this.ordenador)
      if (this.ordenador === "maior_preco") {
        this.contador_imoveis.sort(function(a, b) {
          return b.valor_locacao - a.valor_locacao
        })
      }
      if (this.ordenador === "menor_preco") {
        this.contador_imoveis.sort(function(a, b) {
          return a.valor_locacao - b.valor_locacao
        })
      }
      if (this.ordenador === "mais_recentes") {
        this.contador_imoveis.sort(function(a, b) {
          return b.codigo - a.codigo
        })
      }
      var seqimoveis1 = []
      var seqimoveis2 = []
      // Ordena os destaques na frente
      seqimoveis1 = this.pesquisacidades.filter(d =>
        d.tags.indexOf("destaque") !== -1
      )
      seqimoveis2 = this.pesquisacidades.filter(d =>
        d.tags.indexOf("destaque") === -1
      )
      this.pesquisacidades = seqimoveis1.concat(seqimoveis2)
      this.pesquisacidades = this.contador_imoveis.slice(0, this.fimdpage)
      // return null
      // if (this.ordenador === "maior_preco") {
      //   this.pesquisacidades.sort(function(a, b) {
      //     return b.valor_venda - a.valor_venda
      //   })
      // }
    },
    paginando($state) {
      if (!this.showFilters) {
        this.busy = false
        this.page = this.page + 1
        this.paginando_pagina(this.page)
        $state.loaded()
        if (this.pesquisacidades.length === this.contador_imoveis.length && this.contador_imoveis.length > 0) {
          $state.complete()
        }
      }
    },
    paginando_pagina(pagina) {
      this.page = pagina
      this.initpage = (this.page - 1) * 15
      this.fimdpage = this.initpage + 15
      this.pesquisacidades = this.contador_imoveis.slice(0, this.fimdpage)
    },
    setPlace(place) {
      this.currentPlace = place
    },
    addMarker(lat, lng, content) {
      const marker = {
        lat: lat,
        lng: lng
      }
      this.markers.push({ position: marker, infoText: content })
      this.places.push(this.currentPlace)
      // this.center = marker
      this.currentPlace = null
    },
    geolocate: function(lat, lng) {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: lat,
          lng: lng
        }
      })
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position
      this.infoOptions.content = marker.infoText
      this.infoWinOpen = true
      this.currentMidx = idx
    },
    formataValor(valor) {
      const formatter = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
      return `${formatter.format(valor)}`
    },
    abrevia(str) {
      var retorno = ""
      switch (str) {
        case "Apartamento": retorno = "Apto"
          break
        default: retorno = str
          break
      }
      return retorno.substr(0, 4) + "."
    },
    addfiltros (value) {
      this.filtros = value
    },
    pesquisandoimoveis() {
      this.markers = []
      this.contafiltros = 0
      // console.log("é isso mesmo")
      // console.log(this.filtros)
      // console.log(this.filtros.cidade)
      // console.log(this.filtros.tipo_de_imovel)
      // var contandofiltros = 0
      // this.filtros.forEach(element => {
      //   contandofiltros += 1
      // })
      // this.contafiltros = contandofiltros
      var itens = 0
      var arrrec = this.filtros
      var textopesquisa2 = "Pesquisa: \n"
      Object.keys(arrrec).forEach(function(item) {
        // var arrtipos = ["0", 0, "0+", "0,1000000,"]
        if (
          arrrec[item] !== "" &&
          arrrec[item] !== "0" &&
          arrrec[item] !== "0+" &&
          arrrec[item] !== "0,10000," &&
          arrrec[item] !== 0
        ) {
          itens += 1
          var campo = item
          switch (item) {
            case "tipo_de_imovel": campo = "tipo"
              break
            case "dormitorios": campo = "cômodos"
              break
          }
          textopesquisa2 += campo + ": " + arrrec[item] + " \n"
        }
        // console.log(item + " = " + arrrec[item])
      })
      this.textopesquisa = textopesquisa2

      this.contafiltros = itens

      this.tipo_de_imovel = this.filtros.tipo_de_imovel
      this.cidade = this.filtros.cidade
      this.bairro = this.filtros.bairros
      this.dormitorios = this.filtros.dormitorios
      this.garagens = this.filtros.garagens
      this.banheiros = this.filtros.banheiros
      this.valor = this.filtros.valor
      this.area_util = this.filtros.area
      // this.condominio_nome = this.$route.query.condominio_nome
      // if (this.$route.query.condominio) {
      //   this.condominio_nome = this.$route.query.condominio
      // }
      // this.area_util = this.$route.query.area_util
      this.comodidades = this.filtros.comodidades
      this.caracteres = this.filtros.caracteres
      var tipos = "Todos"
      if (this.tipo_de_imovel && this.tipo_de_imovel !== undefined && this.tipo_de_imovel !== "null") {
        tipos = this.tipo_de_imovel.split(",")
      }
      if (this.cidade === "null" || this.cidade === undefined || this.cidade === "undefined") {
        this.cidade = ""
      }
      var bairros = "Todos"
      if (this.bairro && this.bairro !== undefined && this.bairro !== "null" && this.bairro !== "undefined") {
        bairros = this.bairro.split(",")
      }
      var condominio = ""
      if (this.condominio_nome && this.condominio_nome !== undefined && this.condominio_nome !== "null" && this.condominio_nome !== "undefined") {
        condominio = this.condominio_nome
      }
      var valores = [0, 10000]
      if (this.valor && this.valor !== undefined && this.valor !== "null" && this.valor !== "undefined") {
        valores = this.valor.split(",")
      }
      if (this.area_util === "null" || this.area_util === undefined || this.area_util === "undefined") {
        this.area_util = "0"
      }
      var maiscomodidades = "Todos"
      if (this.comodidades && this.comodidades !== undefined && this.comodidades !== "null" && this.comodidades !== "undefined") {
        var thiscomodidades = this.comodidades.replace(/.$/, "")
        maiscomodidades = thiscomodidades.split(",")
      }
      var arrcaracteres = this.caracteres.split(",")
      // console.log(arrcaracteres)
      var mobiliado = 0
      var semimobiliado = 0
      var exclusivo = false
      var construcao = false
      var mobisemi = false
      arrcaracteres.forEach(element => {
        switch (element) {
          case "Mobiliado":
            mobiliado = 1
            break
          case "Semimobiliado":
            semimobiliado = 2
            break
          case "Em construção":
            construcao = true
            break
          case "Exclusivo":
            exclusivo = true
            break
        }
      })
      if (semimobiliado === 2 && mobiliado === 1) {
        mobisemi = true
      }
      var dormitoriosx = "Todos"
      if (this.dormitorios && this.dormitorios !== undefined && this.dormitorios !== "null" && this.dormitorios !== "undefined") {
        this.dormitorios = this.dormitorios.replace("+", "")
        this.dormitorios = this.dormitorios.replace(" ", "")
        dormitoriosx = this.dormitorios.split(",")
      }
      axios.get("jsons/cafeimoveis.json")
        .then((response) => {
          this.imoveis_destaque = response.data
          this.contador_imoveis = this.imoveis_destaque.filter(
            d =>
              (d.contrato === "Locação" || d.contrato === "Compra,Locação") &&
              (!exclusivo || (exclusivo && (d.exclusividade === true))) &&
              (!mobisemi || (mobisemi && (d.mobiliado === 1 || d.mobiliado === 2))) &&
              (mobisemi || (mobiliado === 0 || (d.mobiliado === mobiliado))) &&
              (mobisemi || (semimobiliado === 0 || (d.mobiliado === semimobiliado))) &&
              (!construcao || (construcao && (d.status === "Em construção"))) &&
              ((tipos === "Todos" || tipos.includes(d.subtipo) || tipos.includes(d.tipo)) ||
              (tipos.includes("Casa") && (d.subtipo === "Casa" || d.subtipo === "Sobrado")) ||
              (tipos.includes("Apartamento") && (
                d.subtipo === "Cobertura" ||
                d.subtipo === "Kitnet" ||
                d.subtipo === "Duplex" ||
                d.subtipo === "Triplex" ||
                d.subtipo === "Loft"
              )) ||
              (tipos.includes("Cobertura") && (
                d.subtipo === "Duplex" ||
                d.subtipo === "Triplex"
              )) ||
              (tipos.includes("Duplex") && (
                d.subtipo === "Duplex" ||
                d.observacoes.indexOf("Duplex") !== -1 ||
                d.observacoes.indexOf("duplex") !== -1
              )) ||
              (tipos.includes("Triplex") && (
                d.subtipo === "Triplex" ||
                d.observacoes.indexOf("Triplex") !== -1 ||
                d.observacoes.indexOf("triplex") !== -1
              )) ||
              (tipos.includes("Kitnet/Loft") && (
                d.subtipo === "Kitnet" ||
                d.subtipo === "Loft"
              )) ||
              (tipos.includes("Chácara/Sítio") && (
                d.subtipo === "Chácara" ||
                d.subtipo === "Sítio"
              )) ||
              (tipos.includes("Campo/Fazenda") && (
                d.subtipo === "Campo" ||
                d.subtipo === "Fazenda"
              ))) &&
              ((this.cidade === "" || d.endereco_cidade === this.cidade) ||
              (this.cidade === "Santa Maria" && (
                d.endereco_cidade === "Santa Maria" ||
                d.endereco_cidade === "Itaara" ||
                d.endereco_cidade === "Silveira Martins" ||
                d.endereco_cidade === "São Francisco de Assis" ||
                d.endereco_cidade === "São Martinho da Serra"
              ))) &&
              (bairros === "Todos" || bairros.includes(d.endereco_bairro)) &&
              (condominio === "" || d.condominio_nome === condominio) &&
              // d.dormitorios >= parseInt(this.dormitorios) &&
              (dormitoriosx === "Todos" || dormitoriosx.includes(String(d.dormitorios)) || (
                dormitoriosx.includes("5") && d.dormitorios >= 5
              )) &&
              d.garagens >= parseInt(this.garagens) &&
              d.banheiros >= parseInt(this.banheiros) &&
              d.area_privativa >= parseInt(this.area_util) &&
              (
                (valores[1] < 10000 && (d.valor_locacao >= valores[0] && d.valor_locacao <= valores[1])) ||
                (valores[1] > 9999 && d.valor_locacao >= valores[0])
              ) &&
              (
                (maiscomodidades === "Todos" || (d.imovel_comodidades !== null && this.objetivacomodidades((d.imovel_comodidades + "").split(","), maiscomodidades))) ||
                ((d.condominio_comodidades !== "" && d.condominio_comodidades !== null) && this.objetivacomodidades((d.condominio_comodidades + "").split(","), maiscomodidades))
              )
          ).sort(function(a, b) {
            return b.codigo - a.codigo
          })
          // ordenando a campanha na frente
          var seqimoveis1 = []
          var seqimoveis2 = []
          // Ordena os destaques na frente
          seqimoveis1 = this.pesquisacidades.filter(d =>
            d.tags.indexOf("destaque") !== -1
          )
          seqimoveis2 = this.pesquisacidades.filter(d =>
            d.tags.indexOf("destaque") === -1
          )
          this.pesquisacidades = seqimoveis1.concat(seqimoveis2)
          this.pesquisacidades = this.pesquisacidades.slice(0, this.fimdpage)
          // FIM do ordenando a campanha na frente
          this.pesquisacidades = this.contador_imoveis.sort(function(a, b) {
            return b.codigo - a.codigo
          }).slice(0, 15)
          var localidades = []
          var allimoveisl = []
          var imoveiscomerciais = ["Sala Comercial", "Casa Comercial", "Loja"]
          this.contador_imoveis.forEach(pin => {
            var valor = "Consulte-nos"
            if (pin.valor_locacao > 0) {
              valor = this.formataValor(pin.valor_locacao)
            }
            localidades.push(String(pin.latitude + ";" + pin.longitude))
            var supercard = ""
            supercard += "<div class='card-do-mapa'>"
            supercard += "<a href='imovel/" + pin.codigo + "' target='_blank'>"
            supercard += "<div class='imagem-do-mapa'>"
            supercard += "<label class='mapa-card-codigo'><strong>Cod. " + pin.codigo + "</strong></label>"
            if (pin.thumb) {
              supercard += "<img src='" + pin.thumb + "' style='width: 130px;' />"
            } else {
              supercard += "<img src='/img/logo_a_empresa.png' style='width: 100px;padding-top: 30%;' />"
            }
            supercard += "</div>"
            supercard += "<div class='clearfix'></div>"
            supercard += "<h4 class='texto-do-mapa'>"
            supercard += "<label class='mapa-card-tipo'><strong>"
            supercard += this.abrevia(pin.subtipo)
            supercard += "</strong></label>"
            if (pin.dormitorios > 0) {
              supercard += "<label class='mapa-card-dormitorios'>"
              supercard += pin.dormitorios
              if (imoveiscomerciais.indexOf(pin.subtipo) !== -1) {
                supercard += " " + this.icones.dooropen.html
              } else {
                supercard += " " + this.icones.bed.html
              }
              supercard += "</label>"
            }
            if (pin.garagens > 0) {
              supercard += "<label class='mapa-card-garagens'>"
              supercard += pin.garagens + " " + this.icones.car.html
              supercard += "</label>"
            }
            if (pin.area_privativa > 0) {
              supercard += "<label class='mapa-card-garagens'>"
              supercard += Number(pin.area_privativa).toFixed(0) + " m²"
              supercard += "</label>"
            }
            if (pin.subtipo === "Terreno") {
              supercard += "<label class='mapa-card-garagens'>"
              if (pin.terreno_largura > 0) {
                supercard += this.icones.expandalt.html + " " + pin.terreno_largura + " "
                if (pin.terreno_comprimento > 0) {
                  supercard += " x " + pin.terreno_comprimento
                }
                supercard += " m"
              }
              supercard += "</label>"
            }
            supercard += "</h4>"
            supercard += "<br />"
            supercard += "<label class='mapa-card-valor'><strong>"
            supercard += valor
            supercard += "</strong></label>"
            // supercard += "</div>"
            supercard += "</a>"
            supercard += "</div>"
            allimoveisl.push({
              coord: String(pin.latitude + ";" + pin.longitude),
              infos: supercard
            })
          })
          localidades = ([...new Set(localidades)])
          localidades.forEach(local => {
            var position = local.split(";")
            var imview = ""
            allimoveisl.forEach(element => {
              if (element.coord === local) {
                imview += element.infos + "<br>"
              }
            })
            this.addMarker(Number(position[0]), Number(position[1]), imview)
          })
        })
      history.pushState("Object", "Title", "aluguel?" + "tipo_de_imovel=" + this.filtros.tipo_de_imovel +
        "&cidade=" + this.filtros.cidade +
        "&bairros=" + this.filtros.bairros +
        "&valor=" + this.filtros.valor +
        "&dormitorios=" + this.filtros.dormitorios + "&garagens=" + this.filtros.garagens + "&banheiros=" + this.filtros.banheiros +
        "&area_util=" + this.filtros.area +
        "&comodidades=" + this.filtros.comodidades + this.urlcomplementos
      )
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
    this.$nextTick(() => window.addEventListener("resize", this.updateWidth))
  },
  beforeDestroy() { window.onscroll = null },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth)
  }
}
</script>

<style lang="postcss">
/* Mesmos estilos de Empreendimentos */
  #id-paginacao .c-range-slider {
    width: 80%;
  }
  #id-paginacao .c-input {
    display: none;
  }
</style>
