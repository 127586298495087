<template>
  <transition name="fade" appear>
    <div class="z-100 schedule-calendar">
      <div class="z-100 schedule-calendar__wrapper">
        <div
          class="hidden schedule-calendar__main md:block"
          v-if="showCalendar"
        >
          <calendar
            class="main__calendar"
            v-bind="{ ...calendarProps }"
            @dayclick="changeDay"
          />
          <div class="main__subtitle">
            <span>
              <font-awesome-icon :icon="['far', 'circle']" />
              Hoje
            </span>
            <span>
              <font-awesome-icon icon="circle" />
              Dia selecionado
            </span>
          </div>
        </div>
        <div
          class="schedule-calendar__sidebar"
          :class="{ rounded: !showCalendar }"
        >
          <button class="sidebar__close" title="Fechar" @click="$emit('close')">
            <font-awesome-icon icon="times" />
          </button>
          <div class="sidebar__info">
            <img
              class="sidebar__img"
              src="@/assets/img/booking.svg"
              alt="Agendar visita"
              v-if="!finalizado"
            />
            <div class="sidebar__date">
              <span class="text-3xl font-medium text-primary-500">
                {{ formattedDay.d }}
              </span>
              <span class="text-lg font-medium uppercase text-primary-500">
                {{ formattedDay.m }} <span class="lowercase" v-if="form.hours">às {{ form.hours }}</span>
              </span>
              <span class="text-xs capitalize">
                {{ formattedDay.w }}
              </span>
            </div>
          </div>
          <!-- <form
            action=""
            class="flex flex-wrap w-full notifications__form"
            ref="notifications__form"
            v-on:submit.prevent="enviarContato"
          > -->
          <template v-if="showCalendar">
            <c-input
              v-model="form.day"
              type="date"
              class="block w-full mt-6 text-xs md:hidden"
              label="Dia de visitação"
              placecholder="Selecione"
            />
            <c-select
              label="Horário"
              class="w-full mt-0 mb-4 sidebar__select xs md:mt-8"
              :options="horarios"
              v-model="form.hours"
            />
            <c-button
              class="w-full sidebar__submit primary"
              @click="toggleMode()"
            >
              Avançar
            </c-button>
          </template>
          <template v-else>
            <div v-if="!finalizado">
              <p class="my-4 text-center">
                Agora faltam somente seus dados para receber a confirmação da
                visita.
              </p>
              <c-input
                label="Nome"
                class="w-full text-xs"
                placeholder="Digite seu nome"
                v-model="form.name"
                :invalid="$v.form.name.$invalid && isTouched"
              />
              <c-input
                label="Telefone"
                type="tel"
                class="w-full text-xs"
                mask="tel"
                v-model="form.phone"
                :invalid="$v.form.phone.$invalid && isTouched"
              />
              <c-input
                label="E-mail"
                type="email"
                class="w-full text-xs"
                placeholder="Digite seu e-mail"
                v-model="form.email"
                :invalid="$v.form.email.$invalid && isTouched"
              />
              <div class="flex w-full mt-3 -mx-3">
                <c-button class="w-full" @click="toggleMode()">
                  Voltar
                </c-button>
                <c-button class="w-full sidebar__submit primary" icon="calendar" @click="enviarContato">
                  <!-- @click="$emit('close')" -->
                  Agendar
                </c-button>
              </div>
            </div>
            <div class="text-center" v-else>
              <img
                src="@/assets/img/confirmed.svg"
                alt="Tudo certo!"
                aria-hidden="true"
                class="w-40 m-auto mb-4"
              />
              <p>
                <span class="font-medium text-primary-500">Sua visita foi agendada.</span>
                <br />
                Em breve entraremos em contato para confirmação.
                <br />
                Agradecemos a confiança!
              </p>
              <br>
              <br>
              <c-button class="w-full sidebar__submit primary" icon="close" @click="$emit('close')">
                <!-- @click="$emit('close')" -->
                Fechar
              </c-button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CInput from "./UI/CInput.vue"
import CButton from "./UI/CButton.vue"
import CSelect from "./UI/CSelect.vue"
import { Calendar } from "v-calendar"
import { required, email } from "vuelidate/lib/validators"
import axios from "axios"
export default {
  components: {
    Calendar,
    CSelect,
    CButton,
    CInput
  },
  props: {
    codigo: { type: [String, Number], default: "0" },
    descricao: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      name: "",
      email: "",
      telefone: "",
      isTouched: false,
      showCalendar: true,
      finalizado: false,
      form: {
        day: new Date(),
        hours: null,
        name: null,
        phone: null,
        email: null
      },
      horarios: [
        {
          id: "08:00",
          label: "08:00"
        },
        {
          id: "08:30",
          label: "08:30"
        },
        {
          id: "09:00",
          label: "09:00"
        },
        {
          id: "09:30",
          label: "09:30"
        },
        {
          id: "10:00",
          label: "10:00"
        },
        {
          id: "10:30",
          label: "10:30"
        },
        {
          id: "11:00",
          label: "11:00"
        },
        {
          id: "11:30",
          label: "11:30"
        },
        {
          id: "12:00",
          label: "12:00"
        },
        {
          id: "12:30",
          label: "12:30"
        },
        {
          id: "13:00",
          label: "13:00"
        },
        {
          id: "13:30",
          label: "13:30"
        },
        {
          id: "14:00",
          label: "14:00"
        },
        {
          id: "14:30",
          label: "14:30"
        },
        {
          id: "15:00",
          label: "15:00"
        },
        {
          id: "15:30",
          label: "15:30"
        },
        {
          id: "16:00",
          label: "16:00"
        },
        {
          id: "16:30",
          label: "16:30"
        },
        {
          id: "17:00",
          label: "17:00"
        },
        {
          id: "17:30",
          label: "17:30"
        },
        {
          id: "18:00",
          label: "18:00"
        },
        {
          id: "18:30",
          label: "18:30"
        },
        {
          id: "19:00",
          label: "19:00"
        },
        {
          id: "19:30",
          label: "19:30"
        }
      ]
    }
  },
  computed: {
    formattedDay() {
      return {
        d: this.form.day.toLocaleDateString("pt-BR", { day: "numeric" }),
        m: this.form.day.toLocaleDateString("pt-BR", { month: "long" }),
        w: this.form.day.toLocaleDateString("pt-BR", { weekday: "long" })
      }
    },
    calendarProps() {
      return {
        masks: { weekdays: "WWW" },
        borderWidth: 0,
        minDate: new Date(),
        attributes: [
          {
            key: "today",
            highlight: true,
            dates: new Date()
          },
          {
            key: "day",
            highlight: {
              color: "purple",
              fillMode: "light",
              contentClass: "italic" // Class provided by TailwindCSS
            }
          }
        ]
      }
    }
  },
  mounted() {
    if (localStorage.name) {
      this.form.name = localStorage.name
    }
    if (localStorage.email) {
      this.form.email = localStorage.email
    }
    if (localStorage.telefone) {
      this.form.phone = localStorage.telefone
    }
  },
  methods: {
    changeDay({ date }) {
      this.form.day = date
    },
    toggleMode() {
      this.showCalendar = !this.showCalendar
    },
    currentDateTime() {
      const current = new Date()
      const date = current.getFullYear() + "-" + (current.getMonth() + 1) + "-" + current.getDate()
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds()
      const dateTime = date + " " + time
      return dateTime
    },
    mandaprosleds(form) {
      let formData = new FormData()
      formData.append("fonte", window.location.href)
      formData.append("formulario", "Form. " + form + ": Imóvel " + this.codigo)
      formData.append("utm_medium", this.$route.query.utm_medium)
      formData.append("utm_source", this.$route.query.utm_source)
      formData.append("utm_campaign", this.$route.query.utm_campaign)
      formData.append("utm_id", this.$route.query.utm_id)
      formData.append("utm_term", this.$route.query.utm_term)
      formData.append("utm_content", this.$route.query.utm_content)
      formData.append("data", this.currentDateTime())
      formData.append("obs", "NC")
      axios.post("https://www.cafeimobiliaria.com.br/sistema/api/contatos/create", formData)
    },
    enviarContato() {
      let currentObj = this
      this.isTouched = true
      if (!this.$v.$invalid) {
        axios.post(
          "/api/enviarcontato.php", JSON.stringify({
            fonte: window.location.href,
            imovel: this.codigo + ": " + this.descricao,
            dia: this.formattedDay.d,
            horário: this.form.hours,
            nome: this.form.name,
            telefone: this.form.phone,
            email: this.form.email,
            utm_medium: this.$route.query.utm_medium,
            utm_source: this.$route.query.utm_source,
            utm_campaign: this.$route.query.utm_campaign
          }))
          .then(function (response) {
            currentObj.output = response.data
          })
          .catch(function (error) {
            currentObj.output = error
          })
        this.finalizado = true
        this.isTouched = false
        // guarda em sessão
        localStorage.name = this.form.name
        localStorage.email = this.form.email
        localStorage.telefone = this.form.phone
        if (this.$route.query.utm_medium) {
          this.mandaprosleds("Agendar")
        }
      }
    }
  },
  validations: {
    form: {
      name: { required },
      phone: { required },
      email: { email, required }
    }
  }
}
</script>

<style lang="postcss">
.schedule-calendar {
  @apply fixed top-0 left-0 w-full h-full z-30 flex justify-center items-center;
  background: rgba(0, 0, 0, 0.5);

  &__wrapper {
    @apply rounded bg-white flex;
  }

  &__main {
    @apply p-8;

    .main {
      &__calendar {
        border: 0;
        .vc {
          &-day-content {
            @apply p-4 border border-solid border-transparent;
            &:hover {
              @apply bg-primary-100 border-primary-100;
            }
            &:focus {
              @apply bg-primary-500 border-primary-500 text-white;
            }
          }

          &-title {
            text-transform: capitalize;
          }

          &-grid-cell {
            @apply p-2;
          }

          &-weekday {
            @apply capitalize font-medium border-b-2 border-solid border-gray-200 text-gray-500;
          }

          &-day {
            &:hover {
              .vc-highlight {
                @apply border-primary-100;
              }
            }
          }

          &-text-white {
            @apply text-primary-500;
          }
          &-highlights {
            @apply overflow-visible;
          }
          &-highlight {
            @apply bg-transparent border-2 border-solid border-primary-500 p-4;
          }
        }
      }

      &__subtitle {
        @apply text-xs text-primary-500 font-medium;
        span + span {
          @apply ml-4;
        }
      }
    }
  }

  &__sidebar {
    @apply bg-gray-200 py-8 px-8 flex flex-col items-center justify-center relative rounded-r;
    width: 21em;

    .sidebar {
      &__close {
        @apply absolute top-0 right-0 p-4 px-5;

        &:focus {
          background: transparent;
          @apply text-primary-500;
        }
      }
      &__img {
        @apply mb-4;
        width: 120px;
      }

      &__date {
        span {
          @apply block text-center;
        }
      }
    }
  }
}
</style>
