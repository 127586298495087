<template>
  <div class="property-suggestions">
    <h3 class="text-left title">Encontrar nas proximidades:</h3>
    <div class="flex flex-wrap px-2 mt-1 mb-10 -mx-4 property-suggestions__cards">
      <center>
        <a title="Universidade Franciscana - UFN" alt="Universidade Franciscana - UFN" :href="'/venda?proximidades=UFN' + urlcomplementos" class="c-button btn-sobre-mapa">🏫 UFN</a>
        <a title="Calçadão de Santa Maria" alt="Calçadão de Santa Maria" :href="'/venda?proximidades=Calçadão' + urlcomplementos" class="c-button btn-sobre-mapa">🛣️ Calçadão</a>
        <a title="Universidade Federal de Santa Maria UFSM" alt="Universidade Federal de Santa Maria UFSM" :href="'/venda?proximidades=UFSM' + urlcomplementos" class="c-button btn-sobre-mapa">🏫 UFSM</a>
      </center>
    </div>
    <h3 class="text-left title">Bairros mais pesquisados</h3>
    <div class="flex flex-wrap px-2 -mx-4 property-suggestions__cards">
      <div
        v-for="(city, index) in cities"
        :key="`city-${index}`"
        class="w-1/2 px-2 mb-4 md:w-1/6"
      >
        <a :href="'venda?bairros=' + city.label + urlcomplementos">
          <div class="relative h-32 overflow-hidden rounded property-suggestions__img-wrapper">
            <!-- <img :src="require(`@/assets/img/${city.img}`)" alt="Apartamentos em cidade" /> -->
            <p class="property-suggestions__city">
              {{city.name}}
            </p>
          </div>
        </a>
      </div>
    </div>
    <h3 class="mt-12 text-left title">Pesquisas selecionadas para você</h3>
    <div
      class="flex flex-wrap justify-between property-suggestions__links md:flex-no-wrap"
    >
      <ul  class="mb-8">
        <li style="white-space: nowrap;" v-for="(n, index) in neighborhoods" :key="`apt-${index}`"><a class="c-link" :href="`venda?tipo_de_imovel=Apartamento&bairros=${n.name.value}` + urlcomplementos">Apartamentos em {{n.name.label}}</a></li>
      </ul>
      <ul  class="mb-8">
        <li style="white-space: nowrap;" v-for="(n, index) in neighborhoods" :key="`cas-${index}`"><a class="c-link" :href="`venda?tipo_de_imovel=Casa&bairros=${n.name.value}` + urlcomplementos">Casas em {{n.name.label}}</a></li>
      </ul>
      <ul  class="mb-8">
        <li style="white-space: nowrap;" v-for="(n, index) in neighborhoods" :key="`cob-${index}`"><a class="c-link" :href="`venda?tipo_de_imovel=Cobertura&bairros=${n.name.value}` + urlcomplementos">Coberturas em {{n.name.label}}</a></li>
      </ul>
    </div>
    <div
      class="flex flex-wrap justify-between property-suggestions__links md:flex-no-wrap"
    >
      <ul  class="mb-8">
        <li style="white-space: nowrap;">
          <a class="c-link" :href="'/campanha' + urlcomplementos">Imóveis MCMV</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cities: {
      type: Array,
      default() {
        return [
          {
            img: "imovel.jpg",
            name: "Centro",
            label: "Centro"
          },
          {
            img: "imovel.jpg",
            name: "Camobi",
            label: "Camobi"
          },
          {
            img: "imovel.jpg",
            name: "N. Sra. Medianeira",
            label: "Nossa Senhora Medianeira"
          },
          {
            img: "imovel.jpg",
            name: "N. Sra. de Lourdes",
            label: "Nossa Senhora de Lourdes"
          },
          {
            img: "imovel.jpg",
            name: "N. Sra. das Dores",
            label: "Nossa Senhora das Dores"
          },
          {
            img: "imovel.jpg",
            name: "N. Sra. de Fátima",
            label: "Nossa Senhora de Fátima"
          }
        ]
      }
    },
    neighborhoods: {
      type: Array,
      default() {
        return [
          {
            name: {
              label: "Centro",
              value: "Centro"
            }
          },
          {
            name: {
              label: "Camobi",
              value: "Camobi"
            }
          },
          {
            name: {
              label: "N. Sra. Medianeira",
              value: "Nossa Senhora Medianeira"
            }
          },
          {
            name: {
              label: "N. Sra. de Lourdes",
              value: "Nossa Senhora de Lourdes"
            }
          },
          {
            name: {
              label: "N. Sra. das Dores",
              value: "Nossa Senhora das Dores"
            }
          },
          {
            name: {
              label: "N. Sra. de Fátima",
              value: "Nossa Senhora de Fátima"
            }
          }
        ]
      }
    }
  },
  computed: {
    urlcomplementos() {
      var retorno = ""
      if (window.location.href.includes("utm_")) {
        // (this.$route.query) ? retorno += "&" : retorno += "?"
        // retorno += "?registros=cafe"
        if (this.$route.query.utm_source) {
          retorno += "&utm_source=" + this.$route.query.utm_source
        }
        if (this.$route.query.utm_medium) {
          retorno += "&utm_medium=" + this.$route.query.utm_medium
        }
        if (this.$route.query.utm_campaign) {
          retorno += "&utm_campaign=" + this.$route.query.utm_campaign
        }
        if (this.$route.query.utm_id) {
          retorno += "&utm_id=" + this.$route.query.utm_id
        }
        if (this.$route.query.utm_term) {
          retorno += "&utm_term=" + this.$route.query.utm_term
        }
        if (this.$route.query.utm_content) {
          retorno += "&utm_content=" + this.$route.query.utm_content
        }
        return retorno
      } else {
        return ""
      }
    }
  }
}
</script>

<style lang="postcss">
.property-suggestions {
  &__img-wrapper {
    @apply flex;

    &:before {
      content: "";
      background-color: rgba(0, 0, 0, 0.35);
      @apply w-full block h-full top-0 left-0 text-center absolute;
    }

    img {
      @apply object-cover;
    }
  }

  &__city {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    @apply text-white font-medium absolute w-full text-center;
  }

  &__links {
    li + li {
      @apply pt-2;
    }
  }
}
</style>
